import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "error", "checkbox", "submit", "othersCheckbox", "othersTextField"]

  submit(event) {
    const isChecked = this.checkboxTargets.some(checkbox => checkbox.checked);

    if (!isChecked && !this.othersCheckboxTarget.checked) {
      event.preventDefault();
      this.errorTarget.classList.remove('hidden');
      this.submitTarget.disabled = true;  // Disable the submit button
    } else {
      this.errorTarget.classList.add('hidden');
      // Add "Others:" prefix if the others checkbox is checked
      if (this.othersCheckboxTarget.checked) {
        this.othersTextFieldTarget.value = `Others: ${this.othersTextFieldTarget.value}`;
      }
    }
  }

  change() {
    const isChecked = this.checkboxTargets.some(checkbox => checkbox.checked);

    if (isChecked || this.othersCheckboxTarget.checked) {
      this.errorTarget.classList.add('hidden');
      this.submitTarget.disabled = false;  // Enable the submit button
    }

    // Enable or disable the others text field
    if (this.othersCheckboxTarget.checked) {
      this.othersTextFieldTarget.disabled = false;
    } else {
      this.othersTextFieldTarget.disabled = true;
      this.othersTextFieldTarget.value = '';  // Clear the text field when disabled
    }
  }
}
