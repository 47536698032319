import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "dropdownImage", "createListModal", "createListModalError", "resetTodoModal", "removeFromListTodoModal"]

  connect() {
    this.handleKeydown = this.handleKeydown.bind(this);
    document.addEventListener('keydown', this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown(event) {
    if (this.hasResetTodoModalTarget && !this.resetTodoModalTarget.classList.contains('hidden')) {
      this.handleModalKeydown(event, this.resetTodoModalTarget, 'hideResetTodoModal');
    }
    if (this.hasRemoveFromListTodoModalTarget && !this.removeFromListTodoModalTarget.classList.contains('hidden')) {
      this.handleModalKeydown(event, this.removeFromListTodoModalTarget, 'hideRemoveFromListTodoModal');
    }
    if (this.hasCreateListModalTarget && !this.createListModalTarget.classList.contains('hidden')) {
      this.handleModalKeydown(event, this.createListModalTarget, 'hideCreateListModal');
    }
  }

  handleModalKeydown(event, modal, hideModalMethod) {
    let submitButton = modal.querySelector('input[type="submit"]');
    let cancelButton = modal.querySelector('.cancel-button-class');

    if (event.key === 'Enter') {
      // Trigger the submit button click event
      submitButton.click();
    } else if (event.key === 'Escape') {
      // Trigger the cancel button click event
      this[hideModalMethod](event);
    }
  }

  toggleTodoListDropdown(){
    const todoListDropdownDiv = document.getElementById("todo-list-dropdown-div");
    todoListDropdownDiv.classList.toggle("show");
    this.dropdownImageTarget.classList.toggle("rotated");
  }

  openCreateListModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();

    this.createListModalTarget.classList.remove("hidden");
    document.getElementById('title').focus();
  }

  submitCreateListModal(e){
    if (document.getElementById("title").value!==""){
      this.createListModalTarget.classList.add("hidden");
    }else{
      e.preventDefault();
      e.stopImmediatePropagation();
      this.createListModalErrorTarget.textContent = "Please enter a name of the list";
    }
  }

  hideCreateListModal(e){
    this.createListModalTarget.classList.add("hidden");
  }

  openResetTodoModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    this.resetTodoModalTarget.classList.remove("hidden");
  }

  hideResetTodoModal(e){
    this.resetTodoModalTarget.classList.add("hidden");
  }

  updateMoreActionDetails(event) {
    const checkboxesArray = document.getElementsByName("todo_checkboxes");
    const checkedCheckboxes = Array.from(checkboxesArray).filter(checkbox => checkbox.checked);
    
    const todoIds = checkedCheckboxes.map(checkbox => checkbox.dataset.todoId).join(',');
    const documentTitles = checkedCheckboxes.map(checkbox => checkbox.dataset.documentTitle);

    
    if(document.getElementById('todo-items-name-reset-modal')){
      let todoItems= document.getElementById('todo-items-name-reset-modal');
      todoItems.innerHTML = '';
      documentTitles.forEach(title => {
        let li = document.createElement('li');
        li.textContent = title;
        li.style.listStyleType = 'disc';
        todoItems.appendChild(li);
      });
    }

    if(document.getElementById('todo_items_checkbox_values')){
      document.getElementById('todo_items_checkbox_values').value = todoIds;
    }

    if(document.getElementById('todo_items_remove_checkbox_values')){
      document.getElementById('todo_items_remove_checkbox_values').value = todoIds;
    }
  
    if(document.getElementById('todo_items_move_to_another_list_values')){
      document.getElementById('todo_items_move_to_another_list_values').value = todoIds;
    }

    if(document.getElementById('todo-items-name-remove-from-list-modal')){
      let todoItemsRemove= document.getElementById('todo-items-name-remove-from-list-modal');
      todoItemsRemove.innerHTML = '';
      documentTitles.forEach(title => {
        let liTodoItem = document.createElement('li');
        liTodoItem.textContent = title;
        liTodoItem.style.listStyleType = 'disc';
        todoItemsRemove.appendChild(liTodoItem);
      });
    }
  }

  openRemoveFromListTodoModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    this.removeFromListTodoModalTarget.classList.remove("hidden");
  }

  hideRemoveFromListTodoModal(e){
    this.removeFromListTodoModalTarget.classList.add("hidden");
  }

  handleKeypressToggleTodoListDropdown(event) {
    // Check if the key pressed is Enter
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default action
      this.toggleTodoListDropdown();
    }
  }

  checkEnterFilterKey(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      const checkboxId = event.target.getAttribute('for'); // Get the id of the checkbox associated with the label
      const checkbox = document.getElementById(checkboxId); // Get the checkbox element
      checkbox.click();
    }
  }

  handleKeypressCheckbox(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      event.target.click(); 
    }
  }

  toggleTodoListFiltersAccordion(event) {
    let cookies = document.cookie.split('; ');
    let cookie = cookies.find(row => row.startsWith('todoListFiltersAccordion='));
  
    if(cookie === undefined || cookie.split('=')[1] === "false") {
      document.cookie = "todoListFiltersAccordion=true; path=/";
    }else{
      document.cookie = "todoListFiltersAccordion=false; path=/";
    }
  }
}