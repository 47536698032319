import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

// Connects to data-controller="dashboard--student--completed-available-cases-chart"
export default class extends Controller {
  static values = {
    stats: Object
  }
  connect() {
    const chartContainer = this.element;
    const statsData = this.statsValue;

    (async function() {
      const labels = Object.keys(statsData);
      const completedCasesData = Object.values(statsData).map(value => value.completed_cases);
      const availableCasesData = Object.values(statsData).map(value => value.available_cases);
      const startedCasesData = Object.values(statsData).map(value => value.started_cases);
      const subscribedCasesData = Object.values(statsData).map(value => value.subscribed);

      const datasets = [
        {
          label: 'Completed',
          data: completedCasesData,
          backgroundColor: completedCasesData.map((value, index) => subscribedCasesData[index] ? '#3D266E' : '#696969')
        },
        {
          label: 'Started',
          data: startedCasesData,
          backgroundColor: startedCasesData.map((value, index) => subscribedCasesData[index] ? '#9E76B4' : '#696969')
        },
        {
          label: 'Available',
          data: availableCasesData,
          backgroundColor: availableCasesData.map((value, index) => subscribedCasesData[index] ? '#D9D9D9' : '#696969')
        }
      ];

      if (subscribedCasesData.includes(false)) {
        datasets.push({
          label: 'Available for additional purchase',
          data: [],
          backgroundColor: '#696969'
        });
      }

      new Chart(
        chartContainer,
        {
          type: 'bar',
          data: {
            labels: labels,
            datasets: datasets
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false
                },
                title: {
                  display: true,
                  text: 'Aquifer Courses',
                  color: '#000',
                  font: {
                    family: 'Roboto Condensed',
                    weight: 'bold'
                  }
                }
              },
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: '# of cases/scripts',
                  color: '#000',
                  font: {
                    family: 'Roboto Condensed',
                    weight: 'bold'
                  }
                }
              }
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 10,
                  padding: 20
                }
              }
            }
          }
        }
      );
    })();
  }
}
