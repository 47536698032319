import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "collapsibleElement", "collapserControl" ]

  toggleCollapse(ev) {
    this.collapserControlTarget.classList.toggle('icon-close-box')
    this.collapserControlTarget.classList.toggle('icon-open-box')
    this.collapsibleElementTarget.classList.toggle('hidden')
  }
}
