import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = []

  connect() {}

  change(event) {
    const frame = document.querySelector('turbo-frame#report')
    frame.src = event.target.value;
    frame.reload();
  }
}
