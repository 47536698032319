import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["text", "button", "checkbox", "label", "field", "confidence", "submit", "reset"];

  connect() {
    let targetedNode = this.element.getElementsByClassName("text-highlight");
    if (targetedNode.length > 0 && targetedNode[0]) {
      targetedNode[0].addEventListener(
        "mouseup",
        this.highlightSelectedText.bind(this)
      );
    }
  }

  toggleCheckbox(event) {
    const button = event.currentTarget;
    const checkbox = this.checkboxTargets.find(checkbox => checkbox.value == button.dataset.index);
    const label = this.textTargets.find(text => text.dataset.id == button.dataset.index);

    if (checkbox) {
      checkbox.checked = !checkbox.checked;

      // Add or remove the strikethrough class from the label
      if (checkbox.checked) {
        label.classList.add('strikethrough');
      } else {
        label.classList.remove('strikethrough');
      }
    }
  }

  strikeThrough(e) {
    const targetId =
      e.target.dataset.targetId || e.target.parentElement.dataset.targetId;
    const textTarget = this.textTargets.find((d) => {
      return d.dataset.id == targetId;
    });

    textTarget.classList.toggle("strike-through");
    if (e.target.nodeName == "BUTTON") {
      e.target.classList.toggle("struck");
    } else {
      e.target.parentElement.classList.toggle("struck");
    }
    this.toggleCheckbox(e);
  }

  highlightText(range, className, color) {
    var commonAncestor = range.commonAncestorContainer;
    if (commonAncestor.nodeType !== 1) {
      commonAncestor = commonAncestor.parentNode;
    }
    if (commonAncestor.classList.contains(className)) {
      var span = document.createElement("span");
      span.style.backgroundColor = color; // Use quotes for color names
      span.appendChild(range.extractContents());
      range.insertNode(span);
    } else {
      // Ensure that only text within the selected range is highlighted
      var startContainer = range.startContainer;
      var endContainer = range.endContainer;
      // If the range is within a single text node
      if (startContainer === endContainer && startContainer.nodeType === Node.TEXT_NODE) {
        // Create a new span to apply the highlight
        var highlightSpan = document.createElement("span");
        highlightSpan.className = className;
        var selectedText = range.extractContents();
        highlightSpan.appendChild(selectedText);
        range.insertNode(highlightSpan);
      } else {
        // If the selection spans multiple nodes, we need to handle each node individually
        var nodes = [];

        // Get all text nodes within the selected range
        var iterator = document.createNodeIterator(range.commonAncestorContainer, NodeFilter.SHOW_TEXT, {
          acceptNode: function (node) {
            var nodeRange = document.createRange();
            nodeRange.selectNodeContents(node);
            return (range.compareBoundaryPoints(Range.END_TO_START, nodeRange) < 0 &&
              range.compareBoundaryPoints(Range.START_TO_END, nodeRange) > 0) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
          }
        });

        // Iterate through all the selected nodes and apply highlight
        var currentNode;
        while (currentNode = iterator.nextNode()) {
          nodes.push(currentNode);
        }

        nodes.forEach(function (node) {
          var span = document.createElement("span");
          span.className = className;

          // Extract only the part of the node that falls within the selected range
          var textRange = document.createRange();
          textRange.selectNodeContents(node);

          // Constrain the range to the selected boundaries
          if (node === startContainer) {
            textRange.setStart(node, range.startOffset);
          }
          if (node === endContainer) {
            textRange.setEnd(node, range.endOffset);
          }

          span.appendChild(textRange.extractContents());
          textRange.insertNode(span);
        });

        // Adjust the range to prevent extending beyond the selected text
        if (nodes.length > 1) {
          range.setStartBefore(nodes[1]);
        } else {
          range.setStartBefore(nodes[0]);
        }
        range.setEndAfter(nodes[nodes.length - 1]);
      }
    }
  }

  highlightSelectedText() {
    var selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      var range = selection.getRangeAt(0);
      var selectedText = range.toString();
      if (selectedText !== "") {
        if (document.body.contains(document.querySelector(".dark"))) {
          this.highlightText(range, "highlighted-black", "#002462");
        } else {
          this.highlightText(range, "highlighted", "white");
        }
      }
    }
  }

  openTodoModal(e) {
    fetch(e.target.dataset.url)
      .then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .then(document.getElementById('todo_form_container').classList.remove("hidden"));
  }

  openResetModal() {
    this.resetTarget.classList.remove('hidden');
  }

  hideResetModal() {
    this.resetTarget.classList.add('hidden');
  }

  toggleDropdown() {
    if (window.innerWidth < 991) {
      const sidebar = document.querySelector('.quiz-sidebar')
      sidebar.classList.toggle('active')
      document.querySelector('.mobile-chevron')?.classList.toggle('rotate')
    }
  }

  keyPressToggleDropdown(event){
    if(event.key === "Enter") {
      this.toggleDropdown()
    }
  }

  openSaveQuizTodoModal(event){
    event.preventDefault();
    document.getElementById("save_quiz_todo_modal").classList.remove("hidden");
    document.getElementById("quiz_id").value = event.currentTarget.dataset.quizId;
    let selectElement = document.getElementsByClassName("select-list-save-quiz-todo-modal")[0];
    selectElement.focus();
  }
}
