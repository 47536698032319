import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "selectFilter" ];

  submitFilter() {
    const url    = new URL(window.location.href)
    const params = new URLSearchParams(url.search.slice(1))

    params.delete('filter')
    params.append('filter', this.selectFilterTarget.value)

    url.search = params.toString()

    window.location = url.toString()
  }
}
