import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["graph", "type"]

  // format: { <barKey1>: {<chunkKey1>: percentage1, <chunkKey2>: percentage2,...},
  //           <barKey2>: {<chunkKey1>: percentage1, <chunkKey2>: percentage2,...},
  //           ... }
  get percentages() {
    return JSON.parse(this.graphTarget.dataset.percentages)
  }

  get barKeys() {
    return Object.keys(this.percentages)
  }

  get gridType() {
    if (this.hasTypeTarget && this.typeTarget.dataset.type == "vertical") {
      return "grid-row"
    }
    return "grid-column"
  }

  initialize() {
    if (this.graphTarget) {
      this.setGraphSections(this.graphTarget.children, this.percentages)
    }
  }

  // format: chunks: [ { dataset: { barKey: <barKey1>, chunkKey: <chunkKey1> },
  //                   { dataset: { barKey: <barKey1>, chunkKey: <chunkKey2> },
  //                   ...,
  //                   { dataset: { barKey: <barKey2>, chunkKey: <chunkKey1> },
  //                   ...,
  //                ]
  setGraphSections(chunks, percentages) {
    let totals = {}
    for (const key of this.barKeys) {
      totals[key] = 1
    }
    for (const chunk of chunks) {
      const bar = chunk.dataset.barKey
      if (percentages[bar][chunk.dataset.chunkKey] === 0) {
        chunk.classList.add("hidden")
      }
      const newTotal = totals[bar] + Math.ceil(percentages[bar][chunk.dataset.chunkKey])
      chunk.style.setProperty(this.gridType, `${totals[bar]} / ${newTotal}`)
      totals[bar] = newTotal
    }
  }
}
