import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hideModal() {
    document.getElementById('openQuestionContainer').classList.add("hidden");
  }

  openSaveQuizTodoModal(event) {
    this.hideModal();
    document.getElementById('todoURLButton').click();
    event.stopPropagation();
  }
}
