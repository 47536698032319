import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["restartModal", "grdu", "reason", "pauseModal", "examName",
                    "studentName", "pauseDate", "pauseTime", "pauseReason",
                    "restartCommit",
                    "aqueductTableRow", "defaultExamForm", "highlightedRowsInfo", "highlightedRowsAlert"]

  delayedCheckHighlightedRows() {
    setTimeout(() => {
      this.checkHighlightedRows(); // Call the checkHighlightedRows function after a delay
    }, 100);
  }

  checkHighlightedRows() {
    let aqueductTable     = window.AqueductTable;
    let rows              = aqueductTable.state.rows;
    this.alertForHighlightedRow(rows);
    this.showHighlightedRowInfo(rows);
  }

  changeDefaultForm(ev) {
    const selectEl          = ev.target.closest('select');
    const default_exam_form = selectEl.value;
    const optionEl          = [...(selectEl.children)].find(elem => elem.value == default_exam_form);
    const default_form_name = optionEl.innerText;

    let aqueductTable     = window.AqueductTable;
    let rows              = aqueductTable.state.rows;

    rows.forEach(row => {
      let exam_form = row.exam_form;
      if (exam_form == 'default') {
        exam_form = default_exam_form;
      }
      row.default_form_name = default_form_name;
      row.row_class = row.forms_already_taken.includes(exam_form) ? "highlighted_rows" : "";
    });

    aqueductTable.setState({ rows });
    this.alertForHighlightedRow(rows);
    this.showHighlightedRowInfo(rows);
  }

  changeRosteringForm(ev) {
    let aqueductTable = window.AqueductTable;
    let rows          = aqueductTable.state.rows;
    let row           = rows.find(row => row.grdu_id == this.getGrduId(ev));

    const default_exam_form = this.defaultExamFormTarget.selectedOptions[0].value;
    let exam_form           = this.getExamForm(ev, default_exam_form);

    if(row.forms_already_taken.includes(exam_form)){
      row.row_class = "highlighted_rows";
      $(this.highlightedRowsInfoTarget).show();
    }
    else{
      row.row_class = "";
      $(this.highlightedRowsInfoTarget).hide();
    }
    row.exam_form = exam_form;

    aqueductTable.setState({ rows });
  }

  explainStatus(ev) {
    let grdu_id       = ev.target.closest('tr').querySelector('.grdu_id_cell input[type="hidden"]').value;
    let result
    $.ajax({
      url: "pause_details",
      type: "GET",
      async: false,
      dataType: "json",
      data: {grdu_id: grdu_id},
      success: function(response){
        result = response
      }
    });
    this.examNameTarget.textContent = result.exam_name
    this.studentNameTarget.textContent = result.student_name
    this.pauseDateTarget.textContent = result.pause_date
    this.pauseTimeTarget.textContent = result.pause_start_time+"-"+result.pause_end_time+" "+result.timezone
    this.pauseReasonTarget.textContent = result.pause_reason
    $(this.pauseModalTarget).show();
  }

  pauseClose(){
    $(this.pauseModalTarget).hide();
  }

  displayRestart(ev) {
    this.grduTarget.value = ev.target.closest('tr').querySelector('.grdu_id_cell input[type="hidden"]').value;

    // Transfer row-specific data held in the row's "Restart" button to the submit button element.
    this.restartCommitTarget.dataset.url    = ev.target.dataset.url;

    $(this.restartModalTarget).show();
  }

  closeRestart() {
    $(this.restartModalTarget).hide();
  }

  restartExam(){
    let grdu_id       = this.grduTarget.value;

    // The restart API endpoint is in the commit button's dataset.
    let url     = this.restartCommitTarget.dataset.url

    let aqueductTable = window.AqueductTable;
    let rows          = aqueductTable.state.rows;
    let rowIdx        = rows.findIndex(row => row.grdu_id == grdu_id);
    let assessment_rostering = {
      reason_for_restart: this.reasonTarget.value
    }
    $.ajax({
      url: url,
      type: "PATCH",
      async: false,
      dataType: "json",
      data: {assessment_rostering: assessment_rostering}
    });
    $(this.restartModalTarget).hide();
    rows[rowIdx].status = 'Restarted';
    rows[rowIdx].can_restart = false;
    aqueductTable.setState({ rows });
  }

  getGrduId(ev) {
    return ev.target.closest('tr').querySelector('.grdu_id_cell input[type="hidden"]').value;
  }

  getExamForm(ev, default_exam_form) {
    let exam_form = ev.target.closest('select').value;
    if (exam_form == 'default') {
      exam_form = default_exam_form;
    }
    return exam_form;
  }

  alertForHighlightedRow(rows){
    if (rows.some(row => row.row_class == "highlighted_rows" && row.selected)) {
      $(this.highlightedRowsAlertTarget).show();
    }
  }

  showHighlightedRowInfo(rows){
    if (rows.some(row => row.row_class == "highlighted_rows")){
      $(this.highlightedRowsInfoTarget).show();
    }
    else{
      $(this.highlightedRowsInfoTarget).hide();
    }
  }

  closehighlightedRowsAlert(){
    $(this.highlightedRowsAlertTarget).hide();
  }
}
