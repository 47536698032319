import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "pageMask", "columnCheckbox" ]

  display() {
    $(this.modalTarget).show();
    $(this.pageMaskTarget).show();
  }

  close() {
    $(this.modalTarget).hide();
    $(this.pageMaskTarget).hide();
  }

  toggleColumn(ev) {
    var selection_map_json = this.data.get('selections')
    if (!selection_map_json) {
      selection_map_json = '{}'
    }
    var selection_map = JSON.parse(selection_map_json)

    selection_map[$(ev.target).data('column-name')] = $(ev.target).prop('checked')

    this.data.set('selections', JSON.stringify(selection_map))
  }

  toggleAllColumns(ev) {
    var do_select_all = $(ev.target).prop('checked')
    var selection_map = {}

    this.columnCheckboxTargets.forEach(cb => {
      if ($(cb).data('column-name') == 'select_all') {
        selection_map[$(cb).data('column-name')] = true
        $(cb).prop('checked', true)
      } else {
        selection_map[$(cb).data('column-name')] = do_select_all
        $(cb).prop('checked', do_select_all)
      }
    });

    this.data.set('selections', JSON.stringify(selection_map))
  }

  save() {
    let columnsToHide = Object.entries(JSON.parse(this.data.get('selections'))).map((pair, idx) => {
      return [idx, pair[1]]
    }).filter(pair => {
      return !pair[1]
    }).map(pair => pair[0])

    let url    = new URL(window.location.href)
    let params = new URLSearchParams(url.search.slice(1))

    params.delete('hide_columns')
    params.append('hide_columns', JSON.stringify(columnsToHide))
    params.append('filter_name', $("#filter_name").val())

    url.search = params.toString()
    this.close()

    window.location = url.toString()
  }
}
