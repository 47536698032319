import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['selectCourse', 'selectCase', 'questionSetCourse', 'projectName', 'questionSetModal', 'courseNameModal', 'projectNameModal', 'questionSetId', 'questionSetName', 'addQuestionSet', 'addQuestionSetButtonDiv']

  connect(){
    $("table").tableDnD({
      onDragClass: "myDragClass",
      dragHandle: ".drag-me-only"
    })
  }

  findDocuments(ev){
    if(this.selectCourseTarget.selectedOptions[0].value != "") {
      let url = this.selectCourseTarget.selectedOptions[0].dataset.documentsUrl;
      let result;
      $.ajax({
        url: url,
        type: 'GET',
        async: false,
        dataType: 'json',
        success: function (response) {
          result = response;
        }
      });
      this.selectCaseTarget.disabled = false
      this.selectCaseTarget.options.length = 0;
      let option = window.document.createElement('option');
      option.value = -1;
      option.innerHTML = 'Select Case';
      this.selectCaseTarget.appendChild(option);
      result.documents.forEach((doc) => {
        let option = window.document.createElement('option');
        option.value = doc.id;
        option.innerHTML = doc.title;
        this.selectCaseTarget.appendChild(option);
      });
    }
    else{
      this.selectCaseTarget.disabled = true
    }
  }

  validateSelectCase(ev){
    if(document.getElementById('addQuestionSetButton')){
      let questionSetName= this.questionSetNameTarget.innerHTML
      if(questionSetName==""){
        alert("Please select Question Set")
        ev.preventDefault()
      }
    }else if(document.getElementById('reviewable_id')){
      let documentId = this.selectCaseTarget.selectedOptions[0].value;
      this.selectCaseTarget.setCustomValidity(
        documentId == -1 ? "Please select case" : ""
      );
    }
  }

  validateCourseProjectName(ev){
    let url = ev.target.closest('button').dataset.url
    let projectName = this.projectNameTarget.value
    let course = this.questionSetCourseTarget.selectedOptions[0].value;
    if(projectName.trim()==""){
      alert("Please enter Project Name")
      ev.preventDefault()
    }
    if(course==""){
      alert("Please select Course")
      ev.preventDefault()
    }
    if(projectName.trim()!="" && course!=""){
      let result;
      $.ajax({
        url: url,
        type: 'GET',
        async: false,
        dataType: 'json',
        data: { projectName: projectName, course: course },
        success: function (response) {
          result = response;
        }
      });
      let questionSetTable = window.ProjectQuestionSetTable;
      let questionSetTableRows = questionSetTable.state.rows;
      questionSetTableRows.length = 0;
      result.question_sets.forEach((question_set) => {
        let row = {};
        row.id = question_set.id;
        row.title = question_set.title;
        row.created_at = question_set.created_at;
        row.show_question_set_path=question_set.show_question_set_path
        questionSetTableRows.push(row);
      });
      questionSetTable.setState({ questionSetTableRows });
      this.courseNameModalTarget.textContent=result.course;
      this.projectNameModalTarget.textContent=this.projectNameTarget.value
      $(this.questionSetModalTarget).show();
    }
  }

  questionSetModalClose(){
    $(this.questionSetModalTarget).hide();
  }

  questionSetModalSave(){
    let selQuestionSet = document.querySelector('input[name="question_set[id]"]:checked').value
    let questionSetTable = window.ProjectQuestionSetTable;
    let questionSetTableRows = questionSetTable.state.rows;
    let questionSetName = questionSetTableRows.find(row=> row.id==selQuestionSet).title
    this.questionSetNameTarget.innerHTML=questionSetName
    this.addQuestionSetButtonDivTarget.style.textAlign="right"
    $(this.questionSetModalTarget).hide();
    $("#questionSetSelection").show();
  }

  activateReorderingOfRows(ev){
    $("table").tableDnD({
      onDragClass: "myDragClass",
      dragHandle: ".drag-me-only"
    })
  }

  addRow(ev){
    let projectTeamTable = window.ProjectTeamTable;
    let rows = projectTeamTable.state.rows
    let lastRow = rows[rows.length-1]
    let newRow= {
      id: rows.length>0 ? lastRow.id+1 : 1,
      role: rows.length>0 ? lastRow.role : "editor_in_chief",
      last_name: "",
      first_name: "",
      email: ""
    }
    rows.push(newRow)
    projectTeamTable.setState({ rows })
    this.activateReorderingOfRows(ev)
  }

  deleteRow(ev){
    let row = ev.target.closest('tr')
    row.remove()
  }
}
