import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["alignment"];

  showAlignment() {
    this.alignmentTarget.style.display = 'grid';
  }

  hideAlignment() {
    this.alignmentTarget.style.display = 'none';
  }
}
