import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'startIndicator', 'endIndicator', 'observable'];

  get container() {
    return this.hasContainerTarget ? this.containerTarget : this.element;
  }

  connect() {
    this.handleStartIntersect = this.handleStartIntersect.bind(this);
    this.handleEndIntersect = this.handleEndIntersect.bind(this);
    const startIntersectionObs = new IntersectionObserver(this.handleStartIntersect, { root: this.container, threshold: [0, 0.9] });
    const endIntersectionObs = new IntersectionObserver(this.handleEndIntersect, { root: this.container, threshold: [0, 0.9] });
    if (this.hasObservableTarget) {
      startIntersectionObs.observe(this.observableTargets[0]);
      endIntersectionObs.observe(this.observableTargets?.[this.observableTargets.length - 1]);
    } else {
      if (this.container.firstElementChild !== this.startIndicatorTarget) {
        startIntersectionObs.observe(this.container.firstElementChild);
      }
      if (this.container.lastElementChild !== this.endIndicatorTarget) {
        endIntersectionObs.observe(this.container.lastElementChild);
      }
    }
  }

  handleStartIntersect([e]) {
    if (this.hasStartIndicatorTarget) {
      if (e.intersectionRatio > 0.5) {
        this.startIndicatorTarget.classList.add('hidden');
      } else {
        this.startIndicatorTarget.classList.remove('hidden');
      }
    }
  }

  handleEndIntersect([e]) {
    if (this.hasEndIndicatorTarget) {
      if (e.intersectionRatio > 0.5) {
        this.endIndicatorTarget.classList.add('hidden');
      } else {
        this.endIndicatorTarget.classList.remove('hidden');
      }
    }
  }

  scrollLeft() {
    const scrollIncrement = this.container.dataset.scrollIncrement || this.container.offsetWidth;

    this.container.scroll({
      left: this.container.scrollLeft - parseInt(scrollIncrement),
      behavior: 'smooth',
    });
  }

  scrollRight() {
    const scrollIncrement = this.container.dataset.scrollIncrement || this.container.offsetWidth;

    this.container.scroll({
      left: this.container.scrollLeft + parseInt(scrollIncrement),
      behavior: 'smooth',
    });
  }

  scrollUp() {
    const scrollIncrement = this.container.dataset.scrollIncrement || this.container.scrollHeight;

    this.container.scroll({
      top: this.container.scrollHeight - parseInt(scrollIncrement),
      behavior: 'smooth',
    });
  }

  scrollDown() {
    const scrollIncrement = this.container.dataset.scrollIncrement || this.container.offsetHeight;

    this.container.scroll({
      top: this.container.scrollHeight + parseInt(scrollIncrement),
      behavior: 'smooth',
    });
  }
}
