import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["confirmModal"]

  handleFileChange(){
    this.element.submit();
  }

  importQuestions() {
    $(this.confirmModalTarget).hide();
    this.element.querySelector("#csv_file").click();
  }

  openModal() {
    $(this.confirmModalTarget).show();
  }

  closeModal() {
    $(this.confirmModalTarget).hide();
  }
}
