import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  changeExamVariationStatus(changeEvent) {
    let selectElem = changeEvent.target;
    let newStatus  = selectElem.value
    let url        = selectElem.closest('td').dataset.examVariationUrl

    $.ajax({
      url: url,
      type: "PATCH",
      async: true,
      dataType: "json",
      data: {
        assessment_exam_variation: {
          status: newStatus
        }
      }
    });
  }
}
