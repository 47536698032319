import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["todoListCheckboxes", "checkbox", "heading", "selectLists", "formSubmit", "errorContainer", "noCheckbox"];

  connect() {
    if(this.noCheckboxTarget.dataset.noCheckbox == 'true' && this.noCheckboxTarget.dataset.persisted != 'true') {
      const checkbox = document.getElementById('todoListCheckboxes-0').querySelector("label[for='My To-Dos']").previousElementSibling
      checkbox.checked = true;
    }

    this.updateCheckboxes('todo-form.listCheckboxes-0', 'heading-0');
    this.updateCheckboxes('todo-form.listCheckboxes-1', 'heading-1');
    this.updateCheckboxes('todo-form.listCheckboxes-2', 'heading-2');
    document.addEventListener("click", this.handleClickOutside.bind(this));
    document.addEventListener('keydown', this.listenKeyTaps.bind(this));
    document.getElementById("todo-input-fields-0").focus();

    [0, 1, 2].forEach(index => {
      const checkboxElement = this.targets.find('item-checkbox-' + index);

      if (checkboxElement && !checkboxElement.children[1].checked) {
        const elements = document.getElementsByClassName(checkboxElement.children[1].dataset.target);
        Array.from(elements).forEach ( element => element.classList.add('disabled') );
      }
    });
  }

  toggleTodoListCheckboxes(event) {
    event.preventDefault();
    event.stopPropagation();

    const todoListCheckboxes = document.getElementById(event.currentTarget.dataset.target);
    todoListCheckboxes.classList.toggle('hidden');

    const element = document.getElementById(event.currentTarget.dataset.icon);

    if(todoListCheckboxes.classList.contains('hidden')) {
      element.classList.remove('expanded');
    } else {
      element.classList.add('expanded');
    }
  }  

  updateSelectedLists(event) {
    this.updateCheckboxes(event.target.dataset.target, event.currentTarget.dataset.heading, event.currentTarget.dataset.dropdown, event)
  }

  toggleInputFields(event) {
    const elements = document.getElementsByClassName(event.target.dataset.target);

    if(event.target.checked) {
      Array.from(elements).forEach ( element => element.classList.remove('disabled') );
    } else {
      Array.from(elements).forEach ( element => element.classList.add('disabled') );
    }

    if(event.target.checked && this.targets.find(event.target.dataset.heading).textContent == 'Select list') {
      const myTodoCheckbox = document.getElementById(event.target.dataset.checkboxContainer).querySelector("label[for='My To-Dos']")?.previousElementSibling
      if(myTodoCheckbox) {
        myTodoCheckbox.checked = true;
        this.updateCheckboxes(myTodoCheckbox.dataset.target, event.currentTarget.dataset.heading, event.currentTarget.dataset.dropdown);
      }
    }
  }

  updateCheckboxes(checkboxSelector, headingSelector, dropdownSelector, event) {
    const checkboxes = document.querySelectorAll(`[data-target="${checkboxSelector}"]`);

    if(checkboxes.length > 0) {
      let selectedNames = Array.from(checkboxes).filter(checkbox => checkbox.checked)
                                              .map(checkbox => checkbox.nextElementSibling.textContent.trim())
                                              .join(", ");
      selectedNames = selectedNames.replace(", Create new list", '')

      if(event && event.target.id.includes("new_todo_list_name")) {
        selectedNames = selectedNames + event.target.value;
      }

      if(selectedNames.length >= 0 && dropdownSelector) {
        this.targets.find(dropdownSelector).classList.remove('dropdown-error');
      }

      this.targets.find(headingSelector).textContent = selectedNames || "Select list";
    }
  }

  hideTodoModal(e) {
    document.getElementById('todo_form_container').classList.add("hidden");
    document.getElementById('todoURLButton')?.focus();
  }

  handleClickOutside(event) {
    [0, 1, 2].forEach(index => {
      const element = document.getElementById('todoListCheckboxes-' + index);
      if (element && !element.classList.contains("hidden") && !element.contains(event.target)) {
        element.classList.add("hidden");
      }
    });
  }

  submitTodoForm(e) {
    this.errorContainerTarget.classList.add('hidden');
    let errors = false;
    let checked = false;

    [0, 1, 2].forEach(index => {
      const element = this.targets.find('item-checkbox-' + index);
      const dropdown = this.targets.find('dropdown-' + index);

      if(element && element.children[1].checked) {
        checked = true;

        if(this.targets.find('heading-' + index).textContent == 'Select list') {
          errors = true;
          dropdown.classList.add('dropdown-error');
        }
      }
    })

    if(errors || !checked) {
      e.preventDefault();
      if(!checked) {
        this.errorContainerTarget.classList.remove('hidden');
        this.errorContainerTarget.innerHTML = "Select at least one to-do item to proceed.";
      }
    } else {
      this.hideTodoModal();
    }
  }

  listenKeyTaps(event) {
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
      this.hideTodoModal();
    }
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.formSubmitTarget.click();
    }
  }
}
