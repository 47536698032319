import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["helpModal"]

  openHelpModal(event){
    this.helpModalTarget.classList.remove("hidden");
    document.addEventListener("click", this.hideHelpModal.bind(this));
  }

  hideHelpModal(event){
    if(event.target.id === 'helpModal')
      this.helpModalTarget.classList.add("hidden");
  }
}
