import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["teachingPointModal"];

  hideTeachingPointModal(){
    this.teachingPointModalTarget.classList.add("hidden");
  }

  openTeachingPointModal(){
    window.renderAllChildReactOnRailsComponents?.(this.element);
    this.teachingPointModalTarget.classList.remove("hidden");
  }

  hideTeachingPointModalItemResponse(e){
    const itemResponseId = e.target.dataset.itemResponseId;
    const modal = this.teachingPointModalTargets.find(target => target.id === `tp_modal_${itemResponseId}`);
    if (modal) {
        modal.classList.add("hidden");
    }
  }

  openTeachingPointModalItemResponse(e){
    window.renderAllChildReactOnRailsComponents?.(this.element);
    const itemResponseId = e.target.dataset.itemResponseId;
    const modal = this.teachingPointModalTargets.find(target => target.id === `tp_modal_${itemResponseId}`);
    if (modal) {
        modal.classList.remove("hidden");
    }
  }

}
