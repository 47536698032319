import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    copy(ev) {
        let table = document.getElementById('backupcodes')
        let codes = []
        for (let i in table.rows) {
            let row = table.rows[i]
            for (let j in row.cells) {
                let col = row.cells[j]
                codes.push(col.innerHTML)
            }
        }
        let filteredCodes = codes.filter(function(x) {
            return x !== undefined && x !== "";
        });
        let stringCodes = filteredCodes.toString()
        navigator.clipboard.writeText(stringCodes)
            .then(res => alert("Copied Backup Codes to Clipboard"))
            .catch(err => alert("Copy failed: " + err))
        ev.preventDefault()
    }
}