import { Controller } from "@hotwired/stimulus"

import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 1000)
  }

  submit() {
    this.element.requestSubmit();
    this.updateDisplayCount('Loading...');
    this.showLoader();
  }

  static targets = ["form", "checkbox", "userTypesCheckbox", "userTypesHiddenField",
    "programTypesCheckbox", "programTypesHiddenField",
    "subscriptionTypesCheckbox", "subscriptionTypesHiddenField"]

  updateHiddenField(event) {
    const checkbox = event.target;
    const hiddenFieldId = `${checkbox.id}_hidden`;
    const hiddenField = document.getElementById(hiddenFieldId);
    if (hiddenField) {
      hiddenField.value = checkbox.checked ? "true" : "false";
    }    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 200);
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
  }

  updateDisplayCount(message) {
    const displayCount = document.getElementById('count');
    if (displayCount) {
      displayCount.innerHTML = message;
    }
  }

  showLoader() {
    const desktopLoader = document.getElementById('content-summary-loader');
    const mobileLoader = document.getElementById('content-summary-loader-mobile');
    const mobileContent = document.querySelector('.card-mobile');
    let computedStyle = null;
    if (mobileContent) {
      computedStyle = window.getComputedStyle(mobileContent);
    }

    if (desktopLoader && computedStyle && computedStyle.getPropertyValue('display') === 'none') {
      desktopLoader.style.display = 'block';
    } else if (mobileLoader && computedStyle && computedStyle.getPropertyValue('display') === 'block') {
      mobileLoader.style.display = 'block';
    }
  }
}
