// controllers/grid_list_view_controller.js
import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["grid", "list", "container"];

  connect() {
    // Initialize view state from cookies
    const view = Cookies.get(`view-${this.element.dataset.identifier}`) || "list";
    this.setView(view);
  }

  setView(view) {
    if (view === "grid") {
      this.gridTarget.classList.add("active");
      this.listTarget.classList.remove("active");
      this.containerTarget.classList.add("view-grid");
      this.containerTarget.classList.remove("view-list");
    } else {
      this.gridTarget.classList.remove("active");
      this.listTarget.classList.add("active");
      this.containerTarget.classList.add("view-list");
      this.containerTarget.classList.remove("view-grid");
    }
    // Store the view state in cookies
    Cookies.set(`view-${this.element.dataset.identifier}`, view, { expires: 7 });
  }

  switchToGrid() {
    this.setView("grid");
  }

  switchToList() {
    this.setView("list");
  }
}
