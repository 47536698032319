// This JS will automatically adjust the height of an invisible "pusher" div
// (with element ID +nav-push+) so that the header navigation bar does not
// obscure the top of the main content when the header navigation bar gets
// squeezed, and therefore taller, by the user shrinking the width of their
// browser window.

function resizeNavigation() {
  // Delay the height adjustment, to allow the height to stabilize.
  setTimeout(() => {
    var headerNavigation = $("header.navigation");

    if (headerNavigation.is(":visible") && $("#nav-push").height() != $("header.navigation").height()) {
      $("#nav-push").height($("header.navigation").height())
    }
  })
}

$(document).ready(resizeNavigation);
$(document).on('turbo:load', resizeNavigation);
$(window).resize(resizeNavigation);
