import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["resetModal"]

    openModal(){
        $(this.resetModalTarget).show();
    }

    hideModal(){
        $(this.resetModalTarget).hide();
    }
}