import { Controller } from "@hotwired/stimulus"
import * as React from 'react'
import { createRoot } from "react-dom/client";
import StudentLearningProgressTable from "../src/react/components/Assessment/Reports/StudentLearningProgressTable";

// Connects to data-controller="student-learning-progress-table"
export default class extends Controller {
  static targets = ["progressTable"];

  connect() {
    let reportData = JSON.parse(this.element.dataset.reportData);
    createRoot(this.progressTableTarget).render(<StudentLearningProgressTable {...reportData} />);
  }
}
