import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = []

  initialize() {
    $('.reports-start-date-picker').datepicker({
      dateFormat: 'yy-mm-dd',
    }).on('keypress', function(e){ e.preventDefault();});

    $('.reports-end-date-picker').datepicker({
      dateFormat: 'yy-mm-dd',
    }).on('keypress', function(e){ e.preventDefault();});
  }
}
