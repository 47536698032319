import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  request(e) {
    e.preventDefault();
    if (!e.currentTarget.dataset.url) return;
    fetch(e.currentTarget.dataset.url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.csrfToken
      }
    }).then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}
