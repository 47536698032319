import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "roleSelector", "courseSelections" ]

  connect() {
    this.updateCourseSelectionVisibility()
  }

  changeRole(ev) {
    this.updateCourseSelectionVisibility()
  }

  needsCourseSelections() {
    let target                       = this.roleSelectorTarget;
    let selectedRoleName             = target.options[target.selectedIndex].label;
    let rolesNeedingResponsibilities = JSON.parse(target.dataset.rolesRequiringResponsibilities);

    return rolesNeedingResponsibilities.includes(selectedRoleName);
  }

  updateCourseSelectionVisibility() {
    this.courseSelectionsTarget.hidden = !this.needsCourseSelections();
  }

  submitForm(event) {
    if(this.needsCourseSelections()) {
      let checkboxes = document.getElementById('course-selection-fieldset').querySelectorAll('input[type=checkbox]')
      let checkboxChecked = Array.from(checkboxes).find((element) => element.checked === true);
      if (!checkboxChecked) {
        alert("Please select atleast one course")
        event.preventDefault();
      }
    }
  }
}
