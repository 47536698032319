import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "row", "rowIdsCsv" ];

  get defaultType() {
    return "input.select-box[type='checkbox']";
  }

  selectedRows(type) {
    return this.rowTargets.filter(row => row.querySelector(type)?.checked); //type = "input.select-box[type='checkbox']"
  }

  enabledSelectedRows(type) {
    return this.selectedRows(type).filter(row => !row.querySelector(type).disabled);
  }

  selectAllRows(type) {
    if (typeof(type) != 'string') {
      type = this.defaultType;
    }
    for(const row of this.rowTargets) {
      const rowRep = row.querySelector(type);
      if(!rowRep) continue;
      if(!rowRep.disabled) {
        rowRep.checked = true;
      }
    }
  }

  deselectAllRows(type) {
    if (typeof(type) != 'string') {
      type = this.defaultType;
    }
    for(const row of this.rowTargets) {
      const rowRep = row.querySelector(type);
      if(!rowRep) continue;
      if(!rowRep.disabled) {
        rowRep.checked = false;
      }
    }
  }
}
