import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['filterCourses', 'filterStatus'];

  populateSelectCases(ev) {
    let url = ev.target.closest('select').dataset.url;
    let course_id = this.filterCoursesTarget.selectedOptions[0].value;
    let result;
    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      dataType: 'json',
      data: { course_id: course_id },
      success: function (response) {
        result = response;
      }
    });
    this.refreshCSVURL()
    this.updateProjectTables(result.projects);
  }

  filterProjectStatus(ev) {
    let url = ev.target.closest('select').dataset.url;
    let course_id = document.getElementById("courses").selectedOptions[0].value;
    let status = this.filterStatusTarget.selectedOptions[0].value;
    let result;
    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      dataType: 'json',
      data: { course_id: course_id, statuses: status },
      success: function (response) {
        result = response;
      }
    });
    this.refreshCSVURL()
    this.updateProjectTables(result.projects);
  }

  refreshCSVURL() {
    let course_id = document.getElementById("courses").selectedOptions[0].value;
    let status = document.getElementById("status").selectedOptions[0].value;
    let csv_url = document.getElementById("csv-url")
    csv_url.href = csv_url.href + "?course_ids="+course_id+"&statuses="+status
  }

  updateProjectTables(projects) {
    let projectStatusesTable = window.ProjectStatusesTable;
    let projectStatusesRows = projectStatusesTable.state.rows;
    projectStatusesRows.length = 0;
    projects.forEach((project, index) => {
      let row = {};
      row.course_name = projects[index].course_name;
      row.case_number_and_title = projects[index].case_number_and_title;
      row.review_status = projects[index].review_status;
      row.date_last_updated = projects[index].date_last_updated;
      row.project_team = projects[index].project_team;
      row.project_name = projects[index].project_name;
      row.workflow_report_link = projects[index].workflow_report_link;
      projectStatusesRows.push(row);
    });
    projectStatusesTable.setState({ projectStatusesRows });
  }
}
