import { Controller } from "@hotwired/stimulus";

export default class ReportsDashboard extends Controller {
  static targets = ['bookmarkURLName', 'filterReportType', 'bookmarkModal', 'filterEditors', 'filterStatus', 'filterReviewable', 'startDate', 'endDate', 'reportType', 'filterProjects', 'CasesTarget', 'casesDiv', 'filterCourses', 'caseProjectDetailsModal', 'assessmentProjectDetailsModal', 'projectName', 'courseName', 'caseName', 'priority', 'projectMemberDetails', 'modalTitle', 'assessmentProjectName', 'assessmentCourseName', 'assessmentModalTitle', 'assessmentProjectMemberDetails', 'assessmentPriority', 'startDate', 'assessmentStartDate', 'questionSetTitle', 'projectId', 'questionSetHtml', 'workflowStatus', 'editorTable', 'selectQuestion'];

  populateDashboard(ev) {
    let url = ev.target.dataset.url;
    let projects_type = ev.target.selectedOptions[0].value;
    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      dataType: 'script',
      data: { projects_type: projects_type },
      success: function (response) {
        $('.report-dashboard-datepicker1').datepicker({
          dateFormat: "M d, yy",
          onSelect: function() {
              $("#date_changed").click();
          }
        });
        $('.report-dashboard-datepicker2').datepicker({
          dateFormat: "M d, yy",
          onSelect: function() {
              $("#date_changed").click();
          }
        });
      }
    });
  }

  bookmarkPopupShow(ev) {
    this.bookmarkModalTarget.style.display = "block";
  }

  bookmarkPopupClose(ev) {
    this.bookmarkModalTarget.style.display = "none";
  }

  bookmarkURL(ev) {
    let url = ev.target.dataset.url;

    let bookmarkURLName = this.bookmarkURLNameTarget.value;

    let projects_type = this.filterReportTypeTarget.selectedOptions[0].value;

    let report_type = this.reportTypeTarget.selectedOptions[0].value;

    let course_ids = []
    Array.from(this.filterCoursesTarget.selectedOptions).forEach(function(element){
      course_ids.push(element.value)
    });

    let reviewable_ids = []
    Array.from(this.filterReviewableTarget.selectedOptions).forEach(function(element){
      reviewable_ids.push(element.value)
    });

    let statuses = []
    Array.from(this.filterStatusTarget.selectedOptions).forEach(function(element){
      statuses.push(element.value)
    });

    let project_ids = []
    Array.from(this.filterProjectsTarget.selectedOptions).forEach(function(element){
      project_ids.push(element.value)
    });

    let editor_ids = []
    Array.from(this.filterEditorsTarget.selectedOptions).forEach(function(element){
      editor_ids.push(element.value)
    });

    let startDate = this.startDateTarget.value
    let endDate   = this.endDateTarget.value

    $.ajax({
      url: url,
      type: 'POST',
      async: false,
      dataType: 'script',
      data: { projects_type: projects_type,
              course_ids: course_ids,
              reviewable_ids: reviewable_ids,
              statuses: statuses,
              project_ids: project_ids,
              editor_ids: editor_ids,
              report_type: report_type,
              start_date: startDate,
              end_date: endDate,
              bookmark_url_name: bookmarkURLName
            }
    });
  }

  showReport(ev) {
    let url = ev.target.dataset.url;
    let requestType = ev.target.dataset.type;
    let course_ids = []
    Array.from(this.filterCoursesTarget.selectedOptions).forEach(function(element){
      course_ids.push(element.value)
    });

    let reviewable_ids = []
    Array.from(this.filterReviewableTarget.selectedOptions).forEach(function(element){
      reviewable_ids.push(element.value)
    });

    let statuses = []
    Array.from(this.filterStatusTarget.selectedOptions).forEach(function(element){
      statuses.push(element.value)
    });

    let project_ids = []
    Array.from(this.filterProjectsTarget.selectedOptions).forEach(function(element){
      project_ids.push(element.value)
    });

    let editor_ids = []
    Array.from(this.filterEditorsTarget.selectedOptions).forEach(function(element){
      editor_ids.push(element.value)
    });

    let startDate = this.startDateTarget.value
    let endDate   = this.endDateTarget.value

    let report_type = this.reportTypeTarget.selectedOptions[0].value;

    if(project_ids.length == 0) {
      alert("Please select atleast one project.");
      return 0;
    }

    if(report_type == "") {
      alert("Please select a Report type.");
      return 0;
    }

    let question_id = ""
    if(document.getElementById("question")!=null) {
      question_id = this.selectQuestionTarget.selectedOptions[0].value;
      if(report_type == "Comments Report (single project)" && question_id == "") {
        alert("Please select a Question.");
        return 0;
      }
    }

    $.ajax({
      url: url,
      type: 'POST',
      async: false,
      dataType: 'json',
      data: { course_ids: course_ids,
              reviewable_ids: reviewable_ids,
              statuses: statuses,
              project_ids: project_ids,
              editor_ids: editor_ids,
              report_type: report_type,
              start_date: startDate,
              end_date: endDate,
              question_id: question_id,
              request_type: requestType },
      success: function (response) {
        if(response.error === undefined){
          window.open(response.url, '_blank');
        }
      }
    });
  }

  filterEditors(ev) {
    let url = ev.target.closest('select').dataset.url;
    let project_ids = []
    Array.from(this.filterProjectsTarget.selectedOptions).forEach(function(element){
      project_ids.push(element.value)
    });

    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      dataType: 'script',
      data: { project_ids: project_ids }
    });
  }

  populateSelectCases(ev) {
    let url = ev.target.closest('select').dataset.url;
    let course_ids = []
    Array.from(this.filterCoursesTarget.selectedOptions).forEach(function(element){
      course_ids.push(element.value)
    });
    let result;
    $.ajax({
      url: url,
      type: 'POST',
      async: false,
      dataType: 'script',
      data: { course_ids: course_ids, is_course_changed: true }
    });
  }

  filterProjects(ev) {
    let course_ids = []
    Array.from(this.filterCoursesTarget.selectedOptions).forEach(function(element){
      course_ids.push(element.value)
    });

    let reviewable_ids = []
    Array.from(this.filterReviewableTarget.selectedOptions).forEach(function(element){
      reviewable_ids.push(element.value)
    });

    let statuses = []
    Array.from(this.filterStatusTarget.selectedOptions).forEach(function(element){
      statuses.push(element.value)
    });

    let startDate = this.startDateTarget.value
    let endDate   = this.endDateTarget.value

    let url = this.filterReviewableTarget.closest('select').dataset.url;

    let result;
    $.ajax({
      url: url,
      type: 'POST',
      async: false,
      dataType: 'script',
      data: { course_ids: course_ids, reviewable_ids: reviewable_ids, statuses: statuses, start_date: startDate, end_date: endDate }
    });
  }

  populateSelectQuestions(ev){
    let url = ev.target.closest('select').dataset.url;
    let report_type =this.reportTypeTarget.selectedOptions[0].value
    let project_id=""
    if(report_type=='Comments Report (single project)'){
      project_id = this.filterProjectsTarget.selectedOptions[0].value
      let result;
      $.ajax({
        url: url,
        type: 'GET',
        async: false,
        dataType: 'script',
        data: { assessment_project_id: project_id},
      });
    }
  }
}
