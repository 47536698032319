import { Controller } from "@hotwired/stimulus";
import createChannel from '../cable';

// Connects to data-controller="single-cohort-report"
export default class extends Controller {
  static targets = ['report', 'reportProgress', 'reportProgressBar', 'thankYouMessage', 'hours', 'minutes', 'seconds'];

  connect() {
    this.startTimer();

    var requestId = this.data.get('request');
    if (!window.location.search.includes('request_id=')) {
      var newUrl = window.location.href + (window.location.search ? '&' : '?') + 'request_id=' + requestId;
      window.history.replaceState({}, '', newUrl);
    }

    var jobId = this.data.get('job');
    if (!window.location.search.includes('job_id=')) {
      var newUrl = window.location.href + (window.location.search ? '&' : '?') + 'job_id=' + jobId;
      window.history.replaceState({}, '', newUrl);
    }

    let thisController = this;

    const progressChannel = createChannel({ channel: 'ReportProgressNotifierChannel', request_id: this.data.get('request') }, {
      received({ progress }) {
        console.log("Received data in ReportProgressNotifierChannel");

        if (progress === 100) {
          if (thisController.hasReportProgressBarTarget) {
            thisController.reportProgressBarTarget.style.display = 'none';
          }

          if (thisController.hasThankYouMessageTarget) {
            thisController.thankYouMessageTarget.style.display = 'block';
            thisController.thankYouMessageTarget.innerHTML = "Thank you! Report generation completed.";
          }

          const generatingMessage = document.querySelector('.mt-3');
          if (generatingMessage) {
            generatingMessage.style.display = 'none';
          }

          const progressBar = document.querySelector('.progress-outer');
          if (progressBar) {
            progressBar.style.display = 'none';
          }
        
        } else {
          if (thisController.hasReportProgressBarTarget) {
            thisController.reportProgressBarTarget.style.width = `${progress}%`;
            thisController.reportProgressBarTarget.setAttribute('aria-valuenow', progress);
            thisController.reportProgressBarTarget.innerHTML = `${progress}%`;
          } else {
            console.error("Report progress bar target is not defined.");
          }
        }
      }
    });

    const cohortChannel = createChannel({ channel: 'CohortReportChannel', request_id: this.data.get('request') }, {
      received({ report, complete }) {
        console.log("Received data from CohortReportChannel");
        thisController.reportTarget.innerHTML = report;

        if (complete) {
          thisController.removeIdsFromUrl();
          cohortChannel.unsubscribe();
          progressChannel.unsubscribe();
          
          thisController.disconnectChannels(cohortChannel, progressChannel);
          console.log("Report generation complete. Unsubscribing and disconnecting from channels.");
        }
      },
    });
  }

  startTimer() {
    let totalSeconds = 0;
    this.timerInterval = setInterval(() => {
      totalSeconds++;
      this.updateTimer(totalSeconds);
    }, 1000);
  }

  updateTimer(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    this.hoursTarget.innerHTML = this.pad(hours);
    this.minutesTarget.innerHTML = this.pad(minutes);
    this.secondsTarget.innerHTML = this.pad(seconds);
  }

  pad(val) {
    return val.toString().padStart(2, '0');
  }

  removeIdsFromUrl() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('job_id');
    currentUrl.searchParams.delete('request_id');
    window.history.replaceState({}, '', currentUrl.toString());
  }

  disconnectChannels(cohortChannel, progressChannel) {
    cohortChannel.consumer.disconnect();
    progressChannel.consumer.disconnect();
    clearInterval(this.timerInterval);
  }
}
