import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "preview"];

  connect() {
    this.inputTarget.addEventListener("change", this.previewImage.bind(this));
  }

  previewImage() {
    const file = this.inputTarget.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const imagePreview = document.getElementById("image-preview");

      const image = document.createElement("img");
      image.src = e.target.result;
      image.style.maxWidth = "100%";
      image.style.height = "auto";

      const newImageText = document.createElement("p");
      newImageText.textContent = "New Image: (If you save this form it will replace the current image.)";
      newImageText.style.fontWeight = "bold";
      newImageText.style.fontFamily = "roboto";

      imagePreview.innerHTML = "";
      imagePreview.appendChild(newImageText);
      imagePreview.appendChild(image);

      this.previewTarget.innerHTML = "";
      this.previewTarget.appendChild(image);
    };

    reader.readAsDataURL(file);
  }
}