import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    send() {
        const email = document.getElementById("email").innerText
        $.ajax({
            url: "/two_factor_settings/send_otp",
            type: "GET",
            async: false,
            dataType: "json",
            data: {email: email},
            success: function(response){
                if(response['send_otp'] == false){
                  alert("Something went wrong. Please try again.")
                }else{
                  let result = response
                }
            }
        });
    }
}