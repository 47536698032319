import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  fetchAndOpen(modal) {
    fetch(`/fetch_modal_content/${modal}`)
      .then(response => response.text())
      .then(html => {
        if (html) {
          this.updateModal(html);
          this.open();
        } else {
          console.error("No content received from the server.");
        }
      })
      .catch(error => {
        console.error("Error fetching modal content:", error);
      });
  }

  updateModal(html) {
    const modalElement = this.element.querySelector(".async-modal-content");

    if (modalElement) {
      modalElement.innerHTML = html;
    }
  }

  open() {
    const modal = document.querySelector(".async-modal-dialog-small");
    const scrollY = window.scrollY || window.pageYOffset;
    modal.style.top = `${scrollY + 50}px`;

    this.element.classList.add("modal-active");
    document.body.classList.add("modal-active");
  }

  close() {
    this.element.classList.remove("modal-active");
    document.body.classList.remove("modal-active");
  }
}
