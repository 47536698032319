import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "minutesLimit", "navigation", "questionOrder",
                     "examTitle",
                     "administrationYear", "minRosteringsCount", "blackBackground",
                     "examCourse", "examForm", "examVersion",
                     "detectionNotice"]

  initialize() {
    this.generateTitle()
    this.updateDetectionNotice()
  }

  availableVersions() {
    let url = this.examVersionTarget.dataset.url && new URL(this.examVersionTarget.dataset.url)

    let required_fields_completed =
    this.navigationTarget.value         != "" &&
    this.questionOrderTarget.value      != "" &&
    this.administrationYearTarget.value != "" &&
    this.minutesLimitTarget.vaue        != ""

    if (url && required_fields_completed) {
      // Exam Params
      url.searchParams.append('minutes_limit',       this.minutesLimitTarget.value)
      url.searchParams.append('navigation',          this.navigationTarget.value)
      url.searchParams.append('question_order',      this.questionOrderTarget.value)
      url.searchParams.append('administration_year', this.administrationYearTarget.value)
      url.searchParams.append('black_background',    this.blackBackgroundTarget.checked)
      url.searchParams.append('form',                this.examFormTarget.value)
      url.searchParams.append('title',               this.examTitleTarget.value)
      url.searchParams.append('version',             this.examVersionTarget.value)

      fetch(url)
        .then(response => response.text())
        .then(html => {
          document.getElementById("version-select").innerHTML = html
          this.generateTitle()
        })
    }
  }

  generateTitle() {
    let url = this.administrationYearTarget.dataset.url && new URL(this.administrationYearTarget.dataset.url)

    if (url) {
      url.searchParams.append('administration_year', this.administrationYearTarget.value)
      url.searchParams.append('form', this.examFormTarget.value)
      url.searchParams.append('version', this.examVersionTarget.value)
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.examTitleTarget.outerHTML = html
      })
  }

  updateDetectionNotice() {
    let url = this.detectionNoticeTarget.dataset.url && new URL(this.detectionNoticeTarget.dataset.url)

    if (url) {
      url.searchParams.append('administration_year', this.administrationYearTarget.value)
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.detectionNoticeTarget.innerHTML = html
      })
  }
}
