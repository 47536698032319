// app/javascript/controllers/quiz_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startButton"]

  // connect() {
  //   this.updateButtonHref();
  // }

  updateButtonHref(event) {
    if(document.getElementById("studyMode").checked){
      this.startButtonTarget.href = this.startButtonTarget.href.replace(/mode=.*?(&|$)/, 'mode=study' + '$1');
    } else if(document.getElementById("quizMode").checked) {
      this.startButtonTarget.href = this.startButtonTarget.href.replace(/mode=.*?(&|$)/, 'mode=quiz' + '$1');
    } else {
      event.preventDefault();
      document.getElementById('modeMsg').innerText = "Please select one mode to continue"
    }
  }
}
