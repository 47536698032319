import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['casesDiv', 'filterCourses', 'caseProjectDetailsModal', 'assessmentProjectDetailsModal', 'projectName', 'courseName', 'caseName', 'priority', 'projectMemberDetails', 'modalTitle', 'assessmentProjectName', 'assessmentCourseName', 'assessmentModalTitle', 'assessmentProjectMemberDetails', 'assessmentPriority', 'startDate', 'assessmentStartDate', 'questionSetTitle', 'projectId', 'questionSetHtml', 'workflowStatus', 'editorTable'];

  connect() {
    if(this.hasCaseProjectDetailsModalTarget) {
      $(this.caseProjectDetailsModalTarget).hide();
    }
    if(this.hasAssessmentProjectDetailsModalTarget) {
      $(this.assessmentProjectDetailsModalTarget).hide();
    }
  }

  populateSelectCases(ev) {
    let url = ev.target.closest('select').dataset.url;
    let course_id = this.filterCoursesTarget.selectedOptions[0].value;
    let course_name =  this.filterCoursesTarget.selectedOptions[0].text;
    let cciProjectTable = window.CCIProjectTable;
    let flaggedItemProjectTable = window.FlaggedItemProjectTable;
    let newItemProjectTable = window.NewItemProjectTable
    let result;
    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      dataType: 'json',
      data: { course_id: course_id, course_name: course_name },
      success: function (response) {
        result = response;
      }
    });
    if(cciProjectTable) {
      this.updateCaseProjectTables(cciProjectTable, result.case_projects);
    }
    if(flaggedItemProjectTable) {
      this.updateAssessmentProjectTables(flaggedItemProjectTable, result.flagged_assessment_projects);
    }
    if(newItemProjectTable) {
      this.updateAssessmentProjectTables(newItemProjectTable, result.new_assessment_projects);
    }
  }

  updateCaseProjectTables(projectTable, projects) {
    let tableRows = projectTable.state.rows;
    tableRows.length = 0;
    projects.forEach((project, index) => {
      let row = {};
      row.id = projects[index].id;
      row.name = projects[index].name;
      row.priority = projects[index].priority;
      row.start_date = projects[index].start_date;
      row.status = projects[index].status;
      row.aet_path = projects[index].aet_path;
      row.edit_path = projects[index].edit_path;
      row.details_url= projects[index].details_path;
      row.workflow_status=projects[index].workflow_status;
      row.workflow_status_path=projects[index].workflow_status_path,
      row.report_option=projects[index].report_option,
      row.project_status_report_path=projects[index].project_status_report_path,
      row.project_workflow_report_path=projects[index].project_workflow_report_path,
      row.summary_of_edits_report_path=projects[index].summary_of_edits_report_path,
      row.cases_content_report_path=projects[index].cases_content_report_path,
      row.project_editors_report_path=projects[index].project_editors_report_path,
      row.comments_report_path=projects[index].comments_report_path,
      tableRows.push(row);
    });
    projectTable.setState({ tableRows });
  }

  updateAssessmentProjectTables(projectTable, projects) {
    let tableRows = projectTable.state.rows;
    tableRows.length = 0;
    projects.forEach((project, index) => {
      let row = {};
      row.id = projects[index].id;
      row.name = projects[index].name;
      row.priority = projects[index].priority;
      row.start_date = projects[index].start_date;
      row.status = projects[index].status;
      row.aet_path = projects[index].aet_path;
      row.edit_path = projects[index].edit_path;
      row.details_url= projects[index].details_path;
      row.workflow_status=projects[index].workflow_status;
      row.workflow_status_path=projects[index].workflow_status_path,
      row.report_option=projects[index].report_option,
      row.project_status_report_path=projects[index].project_status_report_path,
      row.project_workflow_report_path=projects[index].project_workflow_report_path,
      row.summary_of_edits_report_path=projects[index].summary_of_edits_report_path,
      row.project_editors_report_path=projects[index].project_editors_report_path,
      tableRows.push(row);
    });
    projectTable.setState({ tableRows });
  }

  projectsDetails(ev) {
    let get_details_url = ev.target.closest('a').dataset.url;
    let projectRoles = {
      'production_associate': 'Production Associate',
      'reviewer': 'Reviewer',
      'case_editor': 'Case Editor',
      'associate_editor': 'Associate Editor',
      'assessment_lead': 'Assessment Lead',
      'editor_in_chief': 'Editor-in-Chief',
      'primary_editor': 'Primary Editor',
      'dyad_reviewer': 'Dyad Reviewer',
      'group_reviewer': 'Group Reviewer',
      'copy_editor': 'Copy Editor',
      'asynchronous_group_reviewer': 'Asynchronous Group Reviewer',
      'synchronous_group_reviewer': 'Synchronous Group Reviewer',
      'author': 'Author'
    };
    let result;
    $.ajax({
      url: get_details_url,
      type: 'GET',
      async: false,
      dataType: 'json',
      success: function (response) {
        result = response;
      }
    });
    if(result.project_type=="CCI Project Details"){
      this.showCaseProjectModal(projectRoles, result)
    }
    else{
      this.showAssessmentProjectModal(projectRoles, result)
    }
  }

  showCaseProjectModal(projectRoles, result){
    this.projectNameTarget.textContent = result.project.name;
    this.courseNameTarget.textContent = result.course_name;
    this.caseNameTarget.textContent = result.case_name;
    this.priorityTarget.textContent = result.project.priority;
    this.startDateTarget.textContent = result.start_date
    this.modalTitleTarget.textContent = result.project_type;
    this.workflowStatusTarget.textContent = result.workflow_status
    let innerHtml = '';
    result.project_members.forEach(function (member) {
      innerHtml = innerHtml + `<div><label className="col-sm-3 col-form-label">${projectRoles[member.role]}: &nbsp;</label><label className="form-control-plaintext">${member.name}</label></div>`;
    });
    $(this.projectMemberDetailsTarget).html(innerHtml);
    $(this.caseProjectDetailsModalTarget).show();
  }

  showAssessmentProjectModal(projectRoles, result){
    this.assessmentProjectNameTarget.textContent = result.project.name;
    this.assessmentCourseNameTarget.textContent = result.course_name;
    this.assessmentPriorityTarget.textContent = result.project.priority;
    this.assessmentStartDateTarget.textContent = result.start_date
    this.assessmentModalTitleTarget.textContent = result.project_type;
    this.projectIdTarget.textContent = result.project.id;
    let innerHtml = '';
    result.project_members.forEach(function (member) {
      innerHtml = innerHtml + `<div><label className="col-sm-3 col-form-label">${projectRoles[member.role]}: &nbsp;</label><label className="form-control-plaintext">${member.name}</label></div>`;
    });

    this.questionSetHtmlTarget.href = result.question_set_url;
    this.questionSetHtmlTarget.innerHTML= result.question_set_title;
    $(this.assessmentProjectMemberDetailsTarget).html(innerHtml);
    let editorHtml = '';
    result.editors_leaderboard.forEach(function (editor) {
      editorHtml = editorHtml + `<tr><td style="background-color: #98CCFD !important; text-align: center">${editor.name}</td><td style="text-align: center">${editor.percentage_assigned_questions_completed}%</td><td style="background-color: #98CCFD !important; text-align: center">${editor.percentage_total_questions_completed}%</td></tr>`
    });
    $(this.editorTableTarget).html(editorHtml);
    $(this.assessmentProjectDetailsModalTarget).show();
  }

  workflowStatus(ev){
    let url = ev.target.closest('a').dataset.url;
    let result;
    let project_id = this.projectIdTarget.value
    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      dataType: 'json',
      data: { project_id: project_id },
      success: function (response) {
        result = response;
      }
    });
  }

  projectDetailsModalClose() {
    $(this.caseProjectDetailsModalTarget).hide();
    $(this.assessmentProjectDetailsModalTarget).hide();
  }

  reportAction(ev){
    let selectElem = ev.target;
    let selectedReport = selectElem.value
    let projectStatusReportUrl = selectElem.closest('td').dataset.projectStatusReportUrl
    let projectWorkflowReportUrl = selectElem.closest('td').dataset.projectWorkflowReportUrl
    let summaryOfEditsReportUrl = selectElem.closest('td').dataset.summaryOfEditsReportUrl
    let casesContentReportUrl=selectElem.closest('td').dataset.casesContentReportUrl
    let projectEditorsReportUrl=selectElem.closest('td').dataset.projectEditorsReportUrl
    let commentsReportUrl=selectElem.closest('td').dataset.commentsReportUrl

    if(selectedReport==="project_status_report"){
      window.open(projectStatusReportUrl)
    }
    if(selectedReport==="project_workflow_report"){
      window.open(projectWorkflowReportUrl)
    }
    if(selectedReport==="summary_of_edits_report"){
      window.open(summaryOfEditsReportUrl)
    }
    if(selectedReport==="cases_content_report"){
      window.open(casesContentReportUrl)
    }
    if(selectedReport==="project_editors_report"){
      window.open(projectEditorsReportUrl)
    }
    if(selectedReport==="comments_report"){
      window.open(commentsReportUrl)
    }
  }
}
