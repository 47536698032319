import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

// Connects to data-controller="dashboard--student--case-learning-time-chart"
export default class extends Controller {
  static values = {
    stats: Object
  }
  connect() {
    const chartContainer = this.element;
    const statsData = this.statsValue;

    (async function() {
      const labels = Object.keys(statsData);
      const data = Object.values(statsData);
      let delayed;

      new Chart(
        chartContainer,
        {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: '#1564A4'
              }
            ]
          },
          options: {
            aspectRatio: false,
            responsive: true,
            barThickness: 5,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  title: function(tooltipItems) {
                    return '';
                  },
                  label: function(context) {
                    const label = context.dataset.label || '';
                    const tooltipText = label ? (label + ' ' + context.parsed.y) : context.parsed.y;
                    return tooltipText + ' minutes on ' + context.label;
                  }
                }
              }
            },
            animation: {
              onComplete: () => {
                delayed = true;
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                  delay = context.dataIndex * 50 + context.datasetIndex * 50;
                }
                return delay;
              },
            },
            scales: {
              x: {
                grid: {
                  display: false
                },
                ticks: {
                  minRotation: 45
                }
              },
              y: {
                grid: {
                  display: true
                },
                title: {
                  display: true,
                  text: 'Minutes',
                  font: {
                    weight: 'bold'
                  }
                }
              }
            }
          }
        }
      );
    })();
  }
}
