import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  haltClickThrough(event) {
    event.preventDefault()
  }

  persist(event) {
    let patchUrl = event.target.dataset.patchUrl
    let newVal = event.target.value
    fetch(patchUrl, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: 'PATCH',
      redirect: 'manual',
      body: JSON.stringify({
        assessment_question: {
          current_use: newVal
        }
      })
    })
  }
}
