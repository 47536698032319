import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "trigger", "modalsContainer"];

  open(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    const modal = this.modalsContainerTarget.querySelector(`#${e.target.id.replace('to_do_trigger_', 'to_do_modal_')}`);
    modal.classList.remove("hidden");
  }

  openTodoModal(e) {
    fetch(e.currentTarget.dataset.url)
      .then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .then(document.getElementById('todo_form_container').classList.remove("hidden"));
  }

  close(e) {
    e.target.closest(".gen-modal.to-do-modal").classList.add("hidden");
  }
}
