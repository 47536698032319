import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "countLabel" ]

  removeItem(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    let documentItemTarget = ev.target.closest('[data-target="reducible-list.documentItem"]')
    documentItemTarget.remove()

    this.updateCountLabel(-1)
  }

  updateCountLabel(adjustment) {
    let currentHTML  = this.countLabelTarget.innerHTML
    let adjustedHTML = this.adjustLabelCount(currentHTML, adjustment);

    this.countLabelTarget.innerHTML = adjustedHTML
  }

  adjustLabelCount(html, adjustment) {
    let matches = html.match(/(\d+) Item/)
    if (!Array.isArray(matches)) return html

    let textToAdjust  = matches[0]
    let countToAdjust = matches[1] && Number(matches[1])
    let newCount      = countToAdjust && (countToAdjust + adjustment)
    let adjustedText  = textToAdjust.replace(/\d+/, `${newCount}`)
    let adjustedHTML  = html.replace(/(\d+) Item/, adjustedText)

    return adjustedHTML;
  }
}
