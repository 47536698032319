import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  showNextDropdown(ev){
    let selectedOption = ev.target.selectedOptions[0].value
    if(ev.params.npWorkflow || ["Resident", "Fellowship", "Practicing clinician"].includes(selectedOption)){
      this.showSpecialityDropdown()
      this.hideClerkshipRotationCourseDropdown()
      if (ev.params.npWorkflow && !["Resident", "Fellowship", "Practicing clinician"].includes(selectedOption)){
        this.showDateFields()
      }else{
        this.hideDateFields()
      }
    }else{
      this.hideSpecialityDropdown()
      this.showClerkshipRotationCourseDropdown()
      this.showDateFields()
    }
    this.showOtherTextBox(ev)
  }

  showOtherTextBox(ev){
    let selectedOption = ev.target.selectedOptions[0].value
    if(["Basic Science","Pre-clerkship/didactic course","Sub-I/Advanced Internship","Elective","Other"].includes(selectedOption)){
      document.getElementById(ev.params.otherTextbox).style.display = 'block'
      document.getElementById(ev.params.otherTextbox).setAttribute("required", "true")
    }else{
      document.getElementById(ev.params.otherTextbox).style.display = 'none'
      document.getElementById(ev.params.otherTextbox).removeAttribute("required", "true")
    }
  }

  showDateFields(){
    document.getElementById("start-date-div").style.display = 'block';
    document.getElementById("end-date-div").style.display = 'block';
    document.getElementById("graduation-day-div").style.display = 'block';
    document.getElementById("start-date-label").classList.add("required");
    document.getElementById("end-date-label").classList.add("required");
    document.getElementById("graduation-day-label").classList.add("required");
    document.getElementById("registration_form_start_date").setAttribute("required", "true")
    document.getElementById("registration_form_end_date").setAttribute("required", "true")
    document.getElementById("registration_form_graduation_day").setAttribute("required", "true")
    document.getElementById("learning-journey-div").classList.add("learning-journey")
    document.getElementById("divider").style.display = 'block'
  }

  hideDateFields(){
    document.getElementById("start-date-div").style.display = 'none';
    document.getElementById("end-date-div").style.display = 'none';
    document.getElementById("graduation-day-div").style.display = 'none';
    document.getElementById("registration_form_start_date").removeAttribute("required", "true")
    document.getElementById("registration_form_end_date").removeAttribute("required", "true")
    document.getElementById("registration_form_graduation_day").removeAttribute("required", "true")
    document.getElementById("registration_form_start_date").value=""
    document.getElementById("registration_form_end_date").value=""
    document.getElementById("learning-journey-div").classList.remove("learning-journey")
    document.getElementById("divider").style.display = 'none'
  }

  showSpecialityDropdown(){
    document.getElementById("speciality-div").style.display = 'block';
    document.getElementById("speciality-label").classList.add("required");
    document.getElementById("registration_form_specialty_main").setAttribute("required", "required")
  }

  hideSpecialityDropdown() {
    document.getElementById("speciality-div").style.display = 'none';
    document.getElementById("registration_form_specialty_main").removeAttribute("required", "required")
    document.getElementById("registration_form_specialty_main").value=""
    document.getElementById("specialty-other-box").value=""
  }

  showClerkshipRotationCourseDropdown(){
    document.getElementById("clerkship-rotation-course-div").style.display = 'block';
    document.getElementById("clerkship-rotation-course-label").classList.add("required");
    document.getElementById("registration_form_clerkship_rotation_course_main").setAttribute("required", "required")
  }

  hideClerkshipRotationCourseDropdown(){
    document.getElementById("clerkship-rotation-course-div").style.display = 'none';
    document.getElementById("registration_form_clerkship_rotation_course_main").removeAttribute("required", "required")
    document.getElementById("registration_form_clerkship_rotation_course_main").value=""
    document.getElementById("clerkship-rotation-course-other-box").value=""
  }

  validateDateFields(event){
    if(document.getElementById("educator-field-div").style.display == 'block') {
      this.validateCheckboxes()
    }
    let startDate = document.getElementById("registration_form_start_date").value;
    if (startDate) {
      if (!this.isValidDate(startDate)) {
        alert("Please enter a valid start date in the format MM-DD-YYYY.");
        event.preventDefault();
        return;
      }
    }

    let endDate = document.getElementById("registration_form_end_date").value;
    if (endDate) {
      if (!this.isValidDate(endDate)) {
        alert("Please enter a valid end date in the format MM-DD-YYYY.");
        event.preventDefault();
        return;
      }
    }

    if (startDate && endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);
    
      if (end <= start) {
        alert("Please select an end date that is after the start date.");
        event.preventDefault();
        return;
      }
    }

    let graduationDate = document.getElementById("registration_form_graduation_day");
    if (graduationDate && graduationDate.value) {
      if (!this.isValidDate(graduationDate.value)) {
        alert("Please enter a valid graduation date in the format MM-DD-YYYY.");
        event.preventDefault();
      }
    }
  }

  isValidDate(dateString) {
    let regex = /^\d{2}-\d{2}-\d{4}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    let parts = dateString.split('-');
    let year = parseInt(parts[2], 10);
    let month = parseInt(parts[0], 10);
    let day = parseInt(parts[1], 10);
    if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31){
      return false; // Invalid year, month, or day
    }
    return true;
  }

  showProgramNameDropdown(ev){
    this.showOtherTextBox(ev)
    let selectedOption = ev.target.selectedOptions[0].value
    let newOptions = []
    const dropdown = document.getElementById("registration_form_program_name_main")
    dropdown.innerHTML = ''
    if(selectedOption == "MD") {
      newOptions = ev.params.mdPrograms
    }else if(selectedOption == "DO"){
      newOptions = ev.params.doPrograms
    }else if(selectedOption == "PA"){
      newOptions = ev.params.paPrograms
    }else if(selectedOption == "NP/DNP/Nursing") {
      newOptions = ev.params.npPrograms
    }else{
      newOptions = ev.params.allPrograms
    }
    newOptions.forEach(optionData => {
      const option = document.createElement("option")
      option.value = optionData
      option.textContent = optionData
      dropdown.appendChild(option)
    });
    document.getElementById("program-name-div").style.display = 'block';
    document.getElementById("program-name-label").classList.add("required");
    document.getElementById("registration_form_program_name_main").setAttribute("required", "required")
    if(ev.params.indSubWorkflow) {
      this.indSubWorkflow(ev)
    }
  }

  changedProfileRole(ev){
    if(ev.params.indSubWorkflow) {
      this.indSubWorkflow(ev)
    }
    document.getElementById("profile-role-title-box").value=""
  }

  showLearningStageDropdown(){
    document.getElementById("learning-stage-div").style.display = 'block';
    document.getElementById("learning-stage-label").classList.add("required");
    document.getElementById("registration_form_learning_stage_main").setAttribute("required", "required")
  }

  hideLearningStageDropdown() {
    document.getElementById("learning-stage-div").style.display = 'none';
    document.getElementById("registration_form_learning_stage_main").removeAttribute("required", "required")
    document.getElementById("registration_form_learning_stage_main").value=""
    document.getElementById("learning-stage-other-box").value=""
  }

  showEducatorFields(){
    document.getElementById("educator-field-div").style.display = 'block';
  }

  hideEducatorFields(){
    document.getElementById("educator-field-div").style.display = 'none';
    document.getElementById("work-with-learners-in").
      querySelectorAll('input[type=checkbox]').forEach(function(checkbox) {
        checkbox.checked = false;
      });

    document.getElementById("work-with-students-in").
      querySelectorAll('input[type=checkbox]').forEach(function (checkbox) {
        checkbox.checked = false;
      });

    document.getElementById("primary-clinical-discipline").
      querySelectorAll('input[type=checkbox]').forEach(function (checkbox) {
        checkbox.checked = false;
      });

    document.getElementById("interests").
      querySelectorAll('input[type=checkbox]').forEach(function (checkbox) {
        checkbox.checked = false;
      });
  }

  indSubWorkflow(ev){
    this.hideDateFields()
    this.hideClerkshipRotationCourseDropdown()
    let selectedRoleEl = document.getElementById("registration_form_profile_role")
    let selectedRole = selectedRoleEl && selectedRoleEl.selectedOptions[0].value
    let selectedProgramType = document.getElementById("registration_form_program_type_main").selectedOptions[0].value
    if(selectedRole && selectedProgramType) {
      if (selectedRole == "Student") {
        this.showLearningStageDropdown()
        this.hideSpecialityDropdown()
        this.hideEducatorFields()
        const learningStageDropdown = document.getElementById("registration_form_learning_stage_main")
        learningStageDropdown.innerHTML = ''
        let newOptions = []
        if (["MD", "DO", "Other"].includes(selectedProgramType)) {
          newOptions = ev.params.mdLsOptions
        } else if (selectedProgramType == "PA") {
          newOptions = ev.params.paLsOptions
        } else if (selectedProgramType == "NP/DNP/Nursing") {
          newOptions = ev.params.npLsOptions
        } else {
          this.hideLearningStageDropdown()
          this.showSpecialityDropdown()
        }
        if (newOptions.length > 0) {
          newOptions.unshift("")
          newOptions.forEach(optionData => {
            const option = document.createElement("option")
            option.value = optionData
            option.textContent = optionData
            learningStageDropdown.appendChild(option)
          });
        }
      } else if (selectedRole == "Resident/Chief Resident/Fellow") {
        this.hideLearningStageDropdown()
        this.showSpecialityDropdown()
        this.hideEducatorFields()
      } else {
        this.showEducatorFields()
        this.hideLearningStageDropdown()
        this.hideSpecialityDropdown()
      }
    }
  }

  updateCheckboxState(event){
    if((event.target.scrollTop + event.target.clientHeight) > (event.target.scrollHeight - 500)) {
      document.getElementById("user-agreement-checkbox").disabled=false
      document.getElementById("user-agreement-label").classList.remove("grayed-out-text")
    }
  }

  validateCheckboxes(){
    let checkboxArray1 = document.getElementById("work-with-learners-in").querySelectorAll('input[type=checkbox]')
    let checkboxCheck1 = Array.from(checkboxArray1).find((element) => element.checked === true);
    if (!checkboxCheck1) {
      alert("Please answer the question `I WORK WITH LEARNERS IN`")
      event.preventDefault();
    }

    if(document.getElementById("work-with-students-in").style.display != 'none') {
      let checkboxArray2 = document.getElementById("work-with-students-in").querySelectorAll('input[type=checkbox]')
      let checkboxCheck2 = Array.from(checkboxArray2).find((element) => element.checked === true);
      if (!checkboxCheck2) {
        alert("Please answer the question `I WORK WITH LEARNERS IN THE FOLLOWING DISCIPLINES`")
        event.preventDefault();
      }
    }

    let checkboxArray3 = document.getElementById("primary-clinical-discipline").querySelectorAll('input[type=checkbox]')
    let checkboxCheck3 = Array.from(checkboxArray3).find((element) => element.checked === true);
    if (!checkboxCheck3) {
      alert("Please answer the question `MY PRIMARY CLINICAL DISCIPLINE IS`")
      event.preventDefault();
    }
  }

  validateDateFieldsPopupForm(event){
    let startDate = document.getElementById("course-reminder-start-date").value;
    let endDate = document.getElementById("course-reminder-end-date").value;
    if (startDate && endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);
    
      if (end <= start) {
        alert("Please select an end date that is after the start date.");
        event.preventDefault();
        return;
      }
    }
  }
}
