import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["div", "element"];
  static values = {
    ssm: Boolean
  }

  connect() {
    const enableOverlay = this.ssmValue;

    if (enableOverlay) {
      if (this.hasDivTarget) {
        this.applyDivOverlay(this.divTarget);
      }
      if (this.hasElementTarget) {
        this.applyElementOverlay(this.elementTarget);
      }
    }
  }

  applyDivOverlay(div) {
    const overlay = document.createElement("div");
    overlay.classList.add("overlay-disabled-div");
    div.appendChild(overlay);

    div.addEventListener("click", (event) => {
      const popup = div.dataset.overlayPopup;
      const trackInfo = div.dataset.overlayTrackInfo ? JSON.parse(div.dataset.overlayTrackInfo) : null;

      if (this.shouldTrackAnalytics(trackInfo)) {
        const { eventName, page, source, metaAttributes } = trackInfo;
        this.trackAnalytics(eventName, page, source, metaAttributes);
      }

      if (popup) {
        this.showPopup(event, popup);
      } else {
        event.preventDefault();
      }
    });

    div.querySelectorAll("a, button, input, textarea, select, [tabindex]").forEach((innerElement) => {
      const innerElementPopup = innerElement.dataset.overlayPopup;

      if (!innerElementPopup) {
        innerElement.setAttribute("tabindex", "-1");
        innerElement.setAttribute("aria-hidden", "true");
        innerElement.addEventListener("click", (event) => event.preventDefault());
      } else {
        innerElement.addEventListener("click", (event) => {
          if (innerElementPopup) {
            this.showPopup(event, innerElementPopup);
          }
        });
      }
    });
  }

  applyElementOverlay(element) {
    const popup = element.dataset.overlayPopup;
    const trackInfo = element.dataset.overlayTrackInfo ? JSON.parse(element.dataset.overlayTrackInfo) : null;
    element.classList.add("overlay-disabled-element");
    element.setAttribute("tabindex", "-1");
    element.setAttribute("aria-hidden", "true");

    if (element.dataset.next) {
      element.dataset.next = "";
    }

    if (this.shouldTrackAnalytics(trackInfo)) {
      const { eventName, page, source, metaAttributes } = trackInfo;
      this.trackAnalytics(eventName, page, source, metaAttributes);
    }

    if (popup) {
      element.addEventListener("click", (event) => this.showPopup(event, popup));
    } else {
      element.addEventListener("click", (event) => event.preventDefault());
    }
    element.addEventListener("contextmenu", (event) => event.preventDefault());
    element.querySelectorAll("a, button, input, textarea, select, [tabindex]").forEach((child) => {
      child.setAttribute("tabindex", "-1");
      child.setAttribute("aria-hidden", "true");
      child.addEventListener("click", (event) => event.preventDefault());
    });
  }

  showPopup(event, popup) {
    event.preventDefault();

    const popupElement = document.getElementById("commonModal");
    if (!popupElement) {
      console.error("Popup element not found.");
      return;
    }

    const modalController = this.application.getControllerForElementAndIdentifier(popupElement, "popup");
    if (modalController) {
      modalController.fetchAndOpen(popup);
    } else {
      console.error("Controller not found.");
    }
  }

  shouldTrackAnalytics(trackInfo) {
    if (!trackInfo) return false;

    const { eventName, page, source } = trackInfo;
    return eventName && page && source;
  }

  trackAnalytics(eventName, page, source, metaAttributes) {
    analytics.track(eventName, {
      page: page,
      source: source,
      ...metaAttributes
    });
  }
}
