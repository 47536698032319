import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["div"];

  connect() {
    setTimeout(() => {
      this.fadeOut();
    }, 3000);
  }

  fadeOut() {
    this.divTarget.style.transition = "opacity 1s";
    this.divTarget.style.opacity = "0";
    setTimeout(() => {
      this.divTarget.style.display = "none";
    }, 1000);
  }
}