import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "updateButton" ]

  updateRowOrder() {
    let aqueductTable     = window.AqueductTable;
    let rows              = aqueductTable.state.rows;

    rows = rows.
      sort(this.compareRows).
      map((row, idx) => {
        row.position         = `${idx+1}.0`
        row.position_integer = idx+1         // MUST be the integer equivalent of the position!!
        return row
      })

    // Reset any column sorting that the user may have been using.
    let sortingColumns = undefined

    aqueductTable.setState({ rows, sortingColumns });
  }

  // The sorting criteria used in this function must exactly match
  // the Ruby code for the Assessment::ExamItem::SortableItem#key method.
  // If you make a change here, you must update the Ruby code to match.
  compareRows(rowA, rowB) {
    // Selected rows are always lower position than unselected rows.
    let aSelected = rowA.selected ? -1 : 1
    let bSelected = rowB.selected ? -1 : 1
    if (aSelected != bSelected) {
      return aSelected - bSelected
    }

    // If this point is reached, both rows have the same selected status.
    // Now we use the position number to compare.
    let aPosition = parseFloat(rowA.position)
    if (isNaN(aPosition)) {
      aPosition = 99999999999
    } else if (rowA.position.trim().match(/^\d+$/)) {
      // Make integers sort into just above or just below the equivalent float value.
      if (aPosition <= rowA.position_integer) {
        aPosition -= 0.000001
      } else {
        aPosition += 0.000001
      }
    }

    let bPosition = parseFloat(rowB.position)
    if (isNaN(bPosition)) {
      bPosition = 99999999999
    } else if (rowB.position.trim().match(/^\d+$/)) {
      // Make integers sort into just above or just below the equivalent float value.
      if (bPosition <= rowB.position_integer) {
        bPosition -= 0.000001
      } else {
        bPosition += 0.000001
      }
    }

    if (aPosition != bPosition) {
      return aPosition - bPosition
    }

    // If this point is reached, both rows have the same selected status
    // and the same position value.
    // Fall back to using the question ID to compare.
    let aQuestionId = parseInt(rowA.question_id)
    if (isNaN(aQuestionId)) { aQuestionId = 99999999999 }
    let bQuestionId = parseInt(rowB.question_id)
    if (isNaN(bQuestionId)) { bQuestionId = 99999999999 }
    return aQuestionId - bQuestionId
  }
}
