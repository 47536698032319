import { Controller } from "@hotwired/stimulus";
import axios from "axios";

import { questionSetColumns, documentSetColumns } from "./basket_target_templates"

export default class extends Controller {
  static targets = [ "modal", "item", "list", "addCount", "viewCount", "viewButton", "all", "totalCount", "basket" ];

  initialize() {
    this.checkAndDisableItems(this.currentSelectableIds);
  }

  get type() {
    return this.basketTarget.dataset.type
  }

  get rowSelecter() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "row-selector"
    );
  }

  get itemClass() {
    return this.element.dataset.itemClass;
  }

  get currentSelectableIds() {
    return this.itemTargets.map((item) => {
      return Number.parseInt(item.dataset.selectableId);
    });
  }

  get basketId() {
    return this.listTarget.dataset.basketId;
  }

  open() {
    this.modalTarget.classList.remove("hidden");
  }

  close() {
    this.modalTarget.classList.add("hidden");
  }

  showViewButton() {
    this.viewButtonTarget.classList.remove("hidden");
  }

  hideViewButton() {
    this.viewButtonTarget.classList.add("hidden");
  }

  clearAddCount() {
    if (this.hasAddCountTarget) {
      this.setCountTargets(this.addCountTargets, 0);
    }
  }

  setAddCount() {
    if (this.hasAddCountTarget) {
      const count = this.rowSelecter?.enabledSelectedRows(this.type).length || 0;
      this.setCountTargets(this.addCountTargets, count);
    }
  }

  setViewCount(count = 0) {
    if (this.hasViewCountTarget) {
      this.setCountTargets(this.viewCountTargets, count);
    }
    this.setTotalCount(count);
  }

  setTotalCount(newCount = 0) {
    if (this.hasTotalCountTarget) {
      const currentNum = Number.parseInt(this.totalCountTarget.dataset.currentNum);
      const newNum = newCount || Number.parseInt(this.viewCountTarget.innerHTML.match(/\d+/));
      this.setCountTargets(this.totalCountTargets, currentNum + newNum);
    }
  }

  setCountTargets(targets, count) {
    targets.forEach((ct) => ct.innerHTML = ct.innerHTML.replace(/\d+/, count));
  }

  addItem(event) {
    // When adding items after navigating to /questions via the "Question Bank"
    // button on the Assessment dashboard, clicking ADD SELECTED ITEMS spawns 2 events
    event.stopImmediatePropagation();

    const items = [{selectable_class: event.target.dataset.itemClass, selectable_id: event.target.dataset.itemId}]
    const data = {
      selection_basket_items: items
    };
    axios({
      data,
      url: `/selection_baskets/${event.target.dataset.basketId}/selection_basket_items`,
      method: "post",
      withCredentials: true,
      headers: {
        'Accept': 'application/json'
      }
    }).then((response) => {
      const list = this.listTarget;
      [...list.children].forEach(c => list.removeChild(c));
      this.constructBasket(JSON.parse(response.data.basket));
      if(response.data.errors.length) {
        window.alert(response.data.errors.join(''));
      }
      this.clearAddCount();
      this.showViewButton();
      this.uncheckSelectAll();
    }).catch((error) => {
      console.log(error);
    });
  }

  addAllItems(event) {
    // When adding items after navigating to /questions via the "Question Bank"
    // button on the Assessment dashboard, clicking ADD SELECTED ITEMS spawns 2 events
    event.stopImmediatePropagation();

    const items = [{selectable_class: event.target.dataset.itemClass, selectable_id: event.target.dataset.itemId}]
    const data = {
      selection_basket_items: items
    };
    axios({
      data,
      url: `/selection_baskets/${event.target.dataset.basketId}/selection_basket_items`,
      method: "post",
      withCredentials: true,
      headers: {
        'Accept': 'application/json'
      }
    }).then((response) => {
      const list = this.listTarget;
      [...list.children].forEach(c => list.removeChild(c));
      this.constructBasket(JSON.parse(response.data.basket));
      if(response.data.errors.length) {
        window.alert(response.data.errors.join(''));
      }
      this.clearAddCount();
      this.showViewButton();
      this.uncheckSelectAll();
    }).catch((error) => {
      console.log(error);
    });
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  addItemsToContentLibraryBasket(event) {
    event.stopImmediatePropagation();

    const csrfToken = this.getCsrfToken();
    const allDocuments = JSON.parse(event.target.dataset.allDocuments);
    const selectionBasketItems = JSON.parse(event.target.dataset.selectionBasketItems);

    const formData = new FormData();
    allDocuments.forEach(documentId => {
      formData.append('all_documents[]', documentId);
    });

    selectionBasketItems.forEach(basketItem => {
      formData.append('selection_basket_items[][selectable_class]', basketItem.selectable_class);
      formData.append('selection_basket_items[][selectable_id]', basketItem.selectable_id);
    });

    fetch(`/selection_baskets/${event.target.dataset.selectionBasketId}/selection_basket_items`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html"
      },
      body: formData
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

  addItems(event) {
    // When adding items after navigating to /questions via the "Question Bank"
    // button on the Assessment dashboard, clicking ADD SELECTED ITEMS spawns 2 events
    event.stopImmediatePropagation();

    // Prevent needlessly sending empty data
    if (this.rowSelecter.enabledSelectedRows(this.type).length === 0) {
      return
    }

    const items = this.rowSelecter.enabledSelectedRows(this.type).map((row) => {
      const data = row.querySelector(".basketItem").dataset;
      return { selectable_class: data.itemClass, selectable_id: data.itemId }
    })
    const data = {
      selection_basket_items: items
    };
    axios({
      data,
      url: `/selection_baskets/${event.target.dataset.basketId}/selection_basket_items`,
      method: "post",
      withCredentials: true,
      headers: {
        'Accept': 'application/json'
      }
    }).then((response) => {
      const list = this.listTarget;
      [...list.children].forEach(c => list.removeChild(c));
      this.constructBasket(JSON.parse(response.data.basket));
      if(response.data.errors.length) {
        window.alert(response.data.errors.join(''));
      }
      this.clearAddCount();
      this.showViewButton();
      this.uncheckSelectAll();
    }).catch((error) => {
      console.log(error);
    });
  }

  removeAllItems() {
    axios({
      url: `${window.origin}/selection_baskets/${this.basketId}/selection_basket_items/destroy_all`,
      method: "delete",
      withCredentials: true,
      headers: {
        'Accept': 'application/json'
      }
    }).then((response) => {
      const list = this.listTarget;
      [...list.children].forEach(c => list.removeChild(c));
      this.setViewCount();
      this.checkAndDisableItems();
      this.close();
      this.hideViewButton();
      this.uncheckSelectAll();
    }).catch((error) => {
      console.log(error);
    });
  }

  removeItem(event) {
    axios({
      url: `${window.origin}/selection_baskets/${this.basketId}/selection_basket_items/${event.target.dataset.itemId}`,
      method: "delete",
      withCredentials: true,
      headers: {
        'Accept': 'application/json'
      }
    }).then((response) => {
      const list = this.listTarget;
      [...list.children].forEach(c => list.removeChild(c));
      this.constructBasket(response.data);
      this.uncheckSelectAll();
    }).catch((error) => {
      console.log(error)
    })
  }

  toggleAll(event) {
    event.stopImmediatePropagation();
    if (this.hasAllTarget) {
      switch (this.allTarget.dataset.value) {
        case "none":
          this.selectAll();
          this.allTarget.setAttribute("data-value", "all");
          this.allTarget.innerHTML = "DESELECT ALL";
          break;
        case "all":
          this.deselectAll();
          this.allTarget.setAttribute("data-value", "none");
          this.allTarget.innerHTML = "SELECT ALL";
          break;
      }
    }
  }

  uncheckSelectAll() {
    if (this.hasAllTarget && this.allTarget.dataset.value == "all") {
      this.allTarget.setAttribute("data-value", "none");
      this.allTarget.innerHTML = "SELECT ALL";
      this.allTarget.checked = false;
    }
  }

  selectAll() {
    this.rowSelecter.selectAllRows(this.type);
    this.setAddCount();
  }

  deselectAll() {
    this.rowSelecter.deselectAllRows(this.type);
    this.setAddCount();
  }

  // column: {
  //  type/*:string*/: elementType,
  //  attributes/*:object*/: {attr: value,...},
  //  content/*:string*/: innerHTML,
  //  children/*:array*/: [child1, child2,...]
  // }
  // child: column
  formatColumn(column) {
    const el = document.createElement(column.type);
    for (const [k,v] of Object.entries(column.attributes)) {
      el.setAttribute(k, v);
    }
    if (column.content) {
      el.innerHTML = column.content;
    }
    column.children?.forEach((child) => {
      el.appendChild(this.formatColumn(child));
    })
    return el;
  }

  formatRow(columns = []) {
    columns.forEach((col) => {
      this.listTarget.appendChild(this.formatColumn(col));
    });
  }

  constructBasket(basketJson) {
    switch (basketJson.target) {
      case "DocumentSet::New":
        this.constructDocumentSetBasket(basketJson);
        break;
      case "Assessment::QuestionSet::Edit":
      case "Assessment::QuestionSet::New":
        this.constructQuestionSetBasket(basketJson);
        break;
    }
  }

  constructQuestionSetBasket(basketJson) {
    if (basketJson.items.length == 0) {
      this.close();
      this.hideViewButton();
    }
    for(const item of basketJson.items) {
      this.formatRow(questionSetColumns(item));
    };
    this.setViewCount(basketJson.items.length);
    this.checkAndDisableItems(basketJson.items.map(i => i.selectable_id));
  }

  constructDocumentSetBasket(basketJson) {
    if (basketJson.items.length == 0) {
      this.close();
      this.hideViewButton();
    }
    for(const item of basketJson.items) {
      this.formatRow(documentSetColumns(item));
    };
    this.setViewCount(basketJson.items.length);
    this.checkAndDisableItems(basketJson.items.map(i => i.selectable_id));
  }

  checkAndDisableItems(items = []) {
    if (this.rowSelecter) {
      for(const row of this.rowSelecter.rowTargets) {
        const rowRep = row.querySelector("input.select-box[type='checkbox']")
        if(!rowRep) continue;
        if (items.includes(Number.parseInt(rowRep.value))) {
          rowRep.checked = true;
          rowRep.disabled = true;
        } else {
          rowRep.checked = false;
          rowRep.disabled = false;
        }
      }
    }
  }
}
