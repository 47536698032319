import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addModal", "unknownModal", "add", "unknown"];

  openAdd() {
    this.addModalTarget.classList.remove("hidden");
  }

  closeAdd() {
    this.addModalTarget.classList.add("hidden");
  }

  openUnknown() {
    this.unknownModalTarget.classList.remove("hidden");
  }

  closeUnknown() {
    this.unknownModalTarget.classList.add("hidden");
  }

  selectRows(items, prop) {
    const aqueductTable = window.AqueductTable;
    const rows = aqueductTable.state.rows;
    let newRows = [];
    let foundItems = [];
    for (const row of rows) {
      if (items.includes(row[prop])) {
        row.selected = true;
        foundItems.push(row[prop]);
        newRows.unshift(row);
      } else {
        newRows.push(row);
      }
    }
    aqueductTable.setState({ rows: newRows });
    this.closeAdd();
    if (foundItems.length !== items.length) {
      let unknownItems = items.filter(item => !foundItems.includes(item));
      if (unknownItems.length) {
        this.unknownTarget.value = unknownItems.join(", ");
        this.openUnknown();
      }
    }
  }

  add() {
    if (this.hasAddTarget && this.addTarget.value.length) {
      let items = [];
      try {
        items = this.addTarget.value.toLowerCase().split(/[,;|/\s]/).filter(a => a !== '');
      } catch (e) {
        console.log(e);
      }

      this.selectRows(items, this.addTarget.dataset.prop);
    } else {
      $('#error').show();
    }
  }

  copy() {
    if (this.hasUnknownTarget && this.unknownTarget.value.length) {
      this.unknownTarget.select();
      this.unknownTarget.setSelectionRange(0, 99999);
      try {
        document.execCommand('copy');
        console.log("Copied the text: " + this.unknownTarget.value);
      } catch (e) {
        console.log(e)
      }
    }
  }
}
