import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  submit(ev) {
    let aqueductTable = window.AqueductTable;
    let rows          = aqueductTable.state.rows;
    const selectedRows = rows.filter(row => row.selected);
    const rowIds       = selectedRows.map(row => row["question_id"]);
    let url = new URL(ev.target.href)
    url.searchParams.append('question_id_list', rowIds)
    ev.preventDefault()
    ev.target.href = url
  }
}
