import { Controller } from "@hotwired/stimulus";
import createChannel from '../cable';

export default class extends Controller {
  static targets = ['report', 'reportProgress', 'reportProgressBar', 'thankYouMessage', 'hours', 'minutes', 'seconds'];

  connect() {
    this.startTimer();

    var requestId = this.data.get('request');
    if (!window.location.search.includes('request_id=')) {
      var newUrl = window.location.href + (window.location.search ? '&' : '?') + 'request_id=' + requestId;
      window.history.replaceState({}, '', newUrl);
    }

    var jobId = this.data.get('job');
    if (!window.location.search.includes('job_id=')) {
      var newUrl = window.location.href + (window.location.search ? '&' : '?') + 'job_id=' + jobId;
      window.history.replaceState({}, '', newUrl);
    }

    const totalReports = this.data.get('total-reports');
    let thisController = this;

    const progressChannel = createChannel({ channel: 'ReportProgressNotifierChannel', request_id: this.data.get('request') }, {
      received({ progress, report_done }) {
        console.log("Received data in ReportProgressNotifierChannel");

        if (progress === 100) {
          if (thisController.hasReportProgressBarTarget) {
            thisController.reportProgressBarTarget.style.display = 'none';
          }

          if (thisController.hasThankYouMessageTarget) {
            thisController.thankYouMessageTarget.style.display = 'block';
            thisController.thankYouMessageTarget.innerHTML = "Thank you! All reports are completed.";
          }

          const generatingMessage = document.querySelector('.mt-3');
          if (generatingMessage) {
            generatingMessage.style.display = 'none';
          }

          const progressBar = document.querySelector('.progress-outer');
          if (progressBar) {
            progressBar.style.display = 'none';
          }

        } else {
          if (thisController.hasReportProgressBarTarget) {
            thisController.reportProgressBarTarget.style.width = `${progress}%`;
            thisController.reportProgressBarTarget.setAttribute('aria-valuenow', progress);
            thisController.reportProgressBarTarget.innerHTML = `${progress}%`;
          } else {
            console.error("Report progress bar target is not defined.");
          }
        }

        if (thisController.hasReportProgressTarget) {
          thisController.reportProgressTarget.innerHTML = `Reports calculated: ${report_done} / ${totalReports}`;
        } else {
          console.error("Report progress target is not defined.");
        }
      },
    });

    const cohortChannel = createChannel({ channel: 'CohortReportChannel', request_id: this.data.get('request') }, {
      received({ report, complete }) {
        console.log("Received data from CohortReportChannel");
        thisController.reportTarget.innerHTML = report;
        thisController.initChart();
        thisController.initBarChart();

        if (complete) {
          thisController.removeIdsFromUrl();
          cohortChannel.unsubscribe();
          progressChannel.unsubscribe();
          
          thisController.disconnectChannels(cohortChannel, progressChannel);
          console.log("Report generation complete. Unsubscribing and disconnecting from channels.");
        }
      },
    });
  }

  startTimer() {
    let totalSeconds = 0;
    this.timerInterval = setInterval(() => {
      totalSeconds++;
      this.updateTimer(totalSeconds);
    }, 1000);
  }

  updateTimer(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    this.hoursTarget.innerHTML = this.pad(hours);
    this.minutesTarget.innerHTML = this.pad(minutes);
    this.secondsTarget.innerHTML = this.pad(seconds);
  }

  pad(val) {
    return val.toString().padStart(2, '0');
  }

  removeIdsFromUrl() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('job_id');
    currentUrl.searchParams.delete('request_id');
    window.history.replaceState({}, '', currentUrl.toString());
  }

  disconnectChannels(cohortChannel, progressChannel) {
    cohortChannel.consumer.disconnect();
    progressChannel.consumer.disconnect();
    clearInterval(this.timerInterval);
  }

  initChart() {
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      try {
        const chartDataElement = document.getElementById('piechart_0');
        if (!chartDataElement) {
          console.error("Chart element not found");
          return;
        }

        const graphData = chartDataElement.dataset.graphData;

        if (!graphData || graphData.length === 0) {
          console.error("Graph data is empty or undefined");
          return;
        }

        const dataRows = JSON.parse(graphData);

        var dataTable = new google.visualization.DataTable();
        dataTable.addColumn('string', 'level');
        dataTable.addColumn('number', 'score');
        dataTable.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
        dataTable.addRows(dataRows);

        var options = {
          legend: 'none',
          colors: ['#297937', '#FAA633', '#ad346A'],
          pieSliceTextStyle: {
            fontName: 'Roboto',
            fontSize: 16
          },
          pieSliceText: 'percentage',
          tooltip: {isHtml: true, trigger: 'selection'},
          backgroundColor: {
            fill: '#FFFFFF',
            fillOpacity: 0.0
          },
          chartArea: {
            height: "90%",
            width: "90%"
          }
        };

        var container = document.getElementById('piechart_0');
        var chart = new google.visualization.PieChart(container);

        google.visualization.events.addListener(chart, 'click', clearSelection);
        document.body.addEventListener('click', clearSelection, false);

        chart.draw(dataTable, options);
        function clearSelection(e) {
          if (!container.contains(e.srcElement)) {
            chart.setSelection();
          }
        }

      } catch (error) {
        console.error("Error parsing JSON data: ", error);
      }
    }
  }

  initBarChart() {
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      try {
        const chartDataElement = document.getElementById('barchart_0');
        if (!chartDataElement) {
          console.error("Bar Chart element not found");
          return;
        }

        const barChartData = chartDataElement.dataset.barChartData;

        if (!barChartData || barChartData.length === 0) {
          console.error("Bar chart data is empty or undefined");
          return;
        }

        const parsedData = JSON.parse(barChartData);

        var data = google.visualization.arrayToDataTable(parsedData);

        var options = {
          colors: ['#297937', '#FAA633', '#ad346A', '#D9D9D9'],
          bar: {groupWidth: "35%"},
          vAxis: {
            title: "# of students",
            titleTextStyle: {
              fontName: 'Roboto',
              italic: false,
            }
          },
          hAxis: {
            textStyle: {fontName: 'Inter'}
          },
          backgroundColor: {
            fill: '#FFFFFF',
            fillOpacity: 0.0
          },
          chartArea: {
            height: "60%",
            width: "70%"
          },
          isStacked: true,
          legend: 'none',
          tooltip: { isHtml: true }
        };

        var container = document.getElementById('barchart_0');
        var chart = new google.visualization.ColumnChart(container);

        chart.draw(data, options);
        window.addEventListener('resize', () => {
          chart.draw(data, options);
        });

      } catch (error) {
        console.error("Error parsing JSON data: ", error);
      }
    }
  }
}
