// app/javascript/controllers/quiz_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["allDisciplines", "allBodySystems", "allClinicalFocuses", "allDiagnoses", "diagnosisCheckboxes", "allClinicalExcellenceTopics", "allClinicalLocations", "numberOfItems", "allCustomCourses", "customCoursesCheckboxes", "searchInput", "checkbox", "clickable", "collapsibleContent", "includePreviouslyCompletedField"];

  connect() {
    this.formTarget.children[0].addEventListener("change", this.handleFormChange.bind(this));
    document.addEventListener('keydown', this.closeModalOnEscape.bind(this));
    window.addEventListener('resize', this.updateActions.bind(this));
    this.updateActions();
    this.updateClasses();
  }

  disconnect() {
    window.removeEventListener('resize', this.updateActions.bind(this));
  }

  get formTarget() {
    return this.targets.find("form");
  }

  handleFormChange() {
    const form = this.formTarget.children[0];
    const formData = new FormData(form);

    const quizData = {};
    formData.forEach((value, key) => {
      if(key.split('[]').length == 1) {
        const formattedKey  = key.replace(/^quiz\[(.*)]$/, '$1');
        quizData[formattedKey] = value;
      } else {
        const formattedKey  = key.split('[]')[0].replace(/^quiz\[(.*)]$/, '$1');
        quizData[formattedKey] ||= [];
        quizData[formattedKey].push(value);
      }
    });

    const payload = { quiz: quizData };

    fetch(this.formTarget.dataset.fetchcount, { 
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html)); 
  }

  toggleCollapse(event) {
    const card = document.getElementById(event.currentTarget.dataset.target);
    const icon = document.getElementById(event.currentTarget.dataset.icon);

    if (card.classList.contains("collapse")) {
      card.classList.remove('collapse');
      card.classList.add('expanded');
      icon.classList.add('expanded');
      if(event.currentTarget.dataset.target == "quickCardContent") {
        document.getElementById('quickStartContent').classList.remove('collapse');
      }
    } else {
      icon.classList.remove('expanded');
      card.classList.remove('expanded');
      card.classList.add('collapse');
      if(event.currentTarget.dataset.target == "quickCardContent") {
        document.getElementById('quickStartContent').classList.add('collapse');
      }
    }
  }

  toggleAllDisciplines() {
    const checked = this.allDisciplinesTarget.checked;
    document.querySelectorAll("[name='quiz[clinical_disciplines][]']").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  toggleAllBodySystems() {
    const checked = this.allBodySystemsTarget.checked;
    document.querySelectorAll("[name='quiz[body_systems][]']").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  toggleAllClinicalFocuses() {
    const checked = this.allClinicalFocusesTarget.checked;
    document.querySelectorAll("[name='quiz[clinical_focuses][]']").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  toggleAllDiagnoses() {
    const checked = this.allDiagnosesTarget.checked;
    document.querySelectorAll("input[type='checkbox'][name='quiz[final_diagnoses][]']:not([style*='display: none'])").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  toggleDiagnosisCheckboxes(event) {
    const diagnosisCheckboxes = this.diagnosisCheckboxesTarget;
    diagnosisCheckboxes.classList.toggle('hidden');

    const element = document.getElementById(event.currentTarget.dataset.target);

    if(diagnosisCheckboxes.classList.contains('hidden')) {
      element.classList.remove('expanded');
    } else {
      element.classList.add('expanded');
    }
  }

  toggleAllClinicalExcellenceTopics() {
    const checked = this.allClinicalExcellenceTopicsTarget.checked;
    document.querySelectorAll("[name='quiz[clinical_excellence][]']").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  toggleAllClinicalLocations() {
    const checked = this.allClinicalLocationsTarget.checked;
    document.querySelectorAll("[name='quiz[clinical_locations][]']").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  toggleCustomCoursesCheckboxes(event) {
    const checkboxes = this.customCoursesCheckboxesTarget;
    checkboxes.classList.toggle('hidden');
    const element = document.getElementById(event.currentTarget.dataset.target);

    if(checkboxes.classList.contains('hidden')) {
      element.classList.remove('expanded');
    } else {
      element.classList.add('expanded');
    }
  }

  toggleAllCustomCourses() {
    const checked = this.allCustomCoursesTarget.checked;
    document.querySelectorAll("[name='quiz[custom_courses][]']").forEach(checkbox => {
      checkbox.checked = checked;
    });
  }

  openModal() {
    const numberOfItemsField = this.numberOfItemsTarget;
    const isValid = numberOfItemsField.checkValidity();

    if (isValid) {
      $('#quizNameModal').modal('show');
      $('#quizNameModal').on('shown.bs.modal', function () {
        document.getElementById('quizName').focus();
      });
    } else {
      numberOfItemsField.reportValidity();
    }
  }

  closeModal() {
    $('#quizNameModal').modal('hide');
  }

  filterCheckboxes() {
    const searchQuery = this.searchInputTarget.value.toLowerCase();

    this.checkboxTargets.forEach(checkbox => {
      const label = checkbox.nextElementSibling;

      if (label.textContent.toLowerCase().includes(searchQuery)) {
        checkbox.style.display = "inline";
        label.style.display = "inline";
      } else {
        checkbox.style.display = "none";
        label.style.display = "none";
      }
    });
  }

  closeModalOnEscape(event) {
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
      this.closeModal();
    }
  }

  updateActions() {
    if (window.matchMedia("(max-width: 500px)").matches) {
      this.clickableTargets.forEach(element => element.setAttribute('data-action', 'click->quiz-form#toggleCollapse'));
    } else {
      this.clickableTargets.forEach(element => element.removeAttribute('data-action'));
    }
  }

  updateClasses() {
    if (window.innerWidth <= 500) {
      this.collapsibleContentTargets.forEach(element => element.classList.add("collapse"));
      document.getElementById('quickCardContent').classList.remove('container');
    } else {
      this.collapsibleContentTargets.forEach(element => element.classList.remove("collapse"));
      document.getElementById('quickCardContent').classList.add('container');
    }
  }

  handleKeypressCheckbox(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      event.target.click(); 
    }
  }

  handleKeypressToggleCustomCoursesCheckboxes(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      this.toggleCustomCoursesCheckboxes(event);
    }
  }

  handleKeypressToggleCollapse(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      this.toggleCollapse(event);
    }
  }

  handleKeypressToggleDiagnosisCheckboxes(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      this.toggleDiagnosisCheckboxes(event);
    }
  }

  customCourseSelected(event) {
    if(event.currentTarget.checked) {
      document.querySelectorAll("[name='quiz[clinical_disciplines][]']").forEach(checkbox => {
        checkbox.checked = false;
      });
      if(this.hasAllDisciplinesTarget) {
        this.allDisciplinesTarget.checked = false;
      }
    }
  }

  clinicalDisciplineSelected(event) {
    if(event.currentTarget.checked) {
      document.querySelectorAll("[name='quiz[custom_courses][]']").forEach(checkbox => {
        checkbox.checked = false;
      });
      if(this.hasAllCustomCoursesTarget) {
        this.allCustomCoursesTarget.checked = false;
      }
    }
  }

  togglePreviousQuestionField(event) {
    if(event.currentTarget.dataset.hide == 'true') {
      this.includePreviouslyCompletedFieldTarget.classList.add('hidden');
    } else {
      this.includePreviouslyCompletedFieldTarget.classList.remove('hidden'); 
    }
  }
}
