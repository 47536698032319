export function questionSetColumns(item) {
  return [
    {
      type: 'div',
      attributes: {"class": 'c1'},
      content: item.selectable_id,
      children: []
    },
    {
      type: 'div',
      attributes: {"class": 'c2 text'},
      content: null,
      children: [
        {
          type: 'a',
          attributes: {
            "class": 'basket-link',
            "href": `${selectableTypePath(item.selectable_type)}${item.selectable_id}`
          },
          content: item.description
        }
      ]
    },
    {
      type: 'div',
      attributes: {"class": 'c3', "data-target": 'basket.item'},
      content: null,
      children: [
        {
          type: 'button',
          attributes: {
            "data-action": 'basket#removeItem',
            "data-basket-id": item.selection_basket_id,
            "data-item-id": item.id
          },
          content: "Remove"
        }
      ]
    }
  ]
}

export function documentSetColumns(item) {
  return [
    {
      type: 'div',
      attributes: {"class": 'c1', "style": "width: 550px; height: 100%;"},
      content: item.description,
      children: []
    },
    {
      type: 'div',
      attributes: {"class": 'c2', "data-target": 'basket.item'},
      content: null,
      children: [
        {
          type: 'button',
          attributes: {
            "data-action": 'basket#removeItem',
            "data-basket-id": item.selection_basket_id,
            "data-item-id": item.id
          },
          content: "Remove"
        }
      ]
    }
  ]
}

function selectableTypePath(type) {
  return {
    "Assessment::Question":"/assessment/questions/",
  }[type]
}
