import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [ "modal", "pageMask", "columnCheckbox" ]

  haltClickThrough(ev) {
    ev.preventDefault()
  }

  persist(ev) {
    let el = $(ev.target)
    let patchUrl = el.data('patch-url')
    let newVal = el.val()
    fetch(patchUrl, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: 'PATCH',
      redirect: 'manual',
      body: JSON.stringify({
        assessment_question: {
          status: newVal
        }
      })
    })
  }
}
