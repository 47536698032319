import { Controller } from "@hotwired/stimulus";
import React from "react";

export default class extends Controller {
  static targets = ["resetModal", "welcomeModal", "successBanner"];

  connect() {
    if (this.hasWelcomeModalTarget){
      this.welcomeModalTarget.classList.remove("hidden")
    }
    this.element.addEventListener("click", this.showBanner);

  }

  hideWelcomeModal(){
    this.welcomeModalTarget.classList.add("hidden");
  }

  openWelcomeModal(){
    this.welcomeModalTarget.classList.remove("hidden");
  }

  hideResetModal(){
    this.resetModalTarget.classList.add("hidden");
  }

  openResetModal(){
    window.renderAllChildReactOnRailsComponents?.(this.element);
    this.resetModalTarget.classList.remove("hidden");
  }

  disconnect() {
    this.element.removeEventListener("click", this.showBanner);
  }

  showBanner = (event) => {

    const banner = document.getElementById("success-banner");
    banner.style.display = "block";

    setTimeout(() => {
      banner.style.display = "none";
    }, 3000);
  };
}
