import { Controller } from "@hotwired/stimulus"
import * as React from 'react'
import { createRoot } from "react-dom/client";
import CohortProgressesDeliveriesTable from "../src/react/components/Assessment/Reports/CohortProgressesDeliveriesTable";

// Connects to data-controller="cohort-progresses-deliveries-table"
export default class extends Controller {
  static targets = ["cohortProgressTable"];

  connect() {
    let reportData = JSON.parse(this.element.dataset.reportData);
    createRoot(this.cohortProgressTableTarget).render(<CohortProgressesDeliveriesTable {...reportData} />);
  }
}
