import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hovercard"
export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");
    } else {
      const html = this.urlValue;
      console.log(html);
      const fragment = document.createRange().createContextualFragment(html);
      this.element.appendChild(fragment);
    }
  }

  hide() {
    console.log("hiding")
    if (this.hasCardTarget) {
      console.log("hascardtarget")
      this.cardTarget.classList.add("hidden");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
