import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['urlTo'];

  connect() {
    if (this.hasUrlToTarget) {
      setTimeout(window.location.replace(this.urlToTarget.dataset.urlTo), 5000);
    }
  }
}
