import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["createQuizModal", "createQuizModalError","createQuizModalMobile", "createQuizModalErrorMobile", "resetQuestionModal", "resetQuestionMobileModal", "teachingPointModal"]

  connect(){
    const filtersDiv = document.getElementById('saq-mobile-filters-div');
    if(filtersDiv){
      filtersDiv.style.display = sessionStorage.getItem('saqMobileFiltersDivDisplay') || "none";
    }
  }

  select_all() {
    const checkboxesArray = document.getElementsByName("saq_response_checkboxes");
    const selectAllCheckbox = document.getElementById('select_all');
    checkboxesArray.forEach((checkbox) => {
      checkbox.checked = selectAllCheckbox.checked;
    });
    this.updateLink();
  }

  updateLink() {
  // Gather all selected checkbox values
    const checkboxesArray = document.getElementsByName("saq_response_checkboxes");
    const checkboxValues = Array.from(checkboxesArray).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value).join(',');

    // Update the hidden field with the checkbox values
    if(document.getElementById('quiz_checkbox_values')){
      document.getElementById('quiz_checkbox_values').value = checkboxValues;
    }

    if(document.getElementById('quiz_checkbox_values_mobile')){
      document.getElementById('quiz_checkbox_values_mobile').value = checkboxValues;
    }

    // Check if the reset_link target is available
    const resetLink = document.getElementById('reset_link');
    if (resetLink) {
      // Update the reset_link URL with the checkbox values
      const restUrl = resetLink.getAttribute('href').replace(/(checkbox_values=)[^\&]*/, `$1${checkboxValues}`);
      resetLink.setAttribute('href', restUrl);
    }

    const resetLinkMobile = document.getElementById('reset_link_mobile');
    if (resetLinkMobile) {
      const restUrlMobile = resetLinkMobile.getAttribute('href').replace(/(checkbox_values=)[^\&]*/, `$1${checkboxValues}`);
      resetLinkMobile.setAttribute('href', restUrlMobile);
    }
  }

  openModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    if(document.getElementById("quiz_checkbox_values").value == ""){
      this.createQuizModalErrorTarget.textContent = "Please select at least one checkbox to create quiz";
    }
    else{
      this.createQuizModalErrorTarget.textContent = "";
    }
    this.createQuizModalTarget.classList.remove("hidden");
  }

  closeModal(e){
    this.createQuizModalTarget.classList.add("hidden");
  }		

  saveModal(e){
    if(document.getElementById("quiz_checkbox_values").value == ""){
      e.preventDefault();
      e.stopImmediatePropagation();
      this.createQuizModalErrorTarget.textContent = "Please select at least one checkbox to create quiz";
    }
    else{
      this.createQuizModalErrorTarget.textContent = "";
      if (document.getElementById("title").value!==""){
        this.createQuizModalTarget.classList.add("hidden");
      }else{
        e.preventDefault();
        e.stopImmediatePropagation();
        this.createQuizModalErrorTarget.textContent = "Please enter a name of the quiz";
      }
    }
  }

  toggleMobileFilters(e){
    e.preventDefault();
    const filtersDiv = document.getElementById('saq-mobile-filters-div');
    if(filtersDiv){
      filtersDiv.style.display = filtersDiv.style.display === "none" ? "block" : "none";
      sessionStorage.setItem('saqMobileFiltersDivDisplay', filtersDiv.style.display);
    }
  }


  openModalMobile(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    if(document.getElementById("quiz_checkbox_values_mobile").value == ""){
      this.createQuizModalErrorMobileTarget.textContent = "Please select at least one checkbox to create quiz";
    }
    else{
      this.createQuizModalErrorMobileTarget.textContent = "";
    }
    this.createQuizModalMobileTarget.classList.remove("hidden");
  }

  closeModalMobile(e){
    this.createQuizModalMobileTarget.classList.add("hidden");
  }		

  saveModalMobile(e){
    if(document.getElementById("quiz_checkbox_values_mobile").value == ""){
      e.preventDefault();
      e.stopImmediatePropagation();
      this.createQuizModalErrorMobileTarget.textContent = "Please select at least one checkbox to create quiz";
    }
    else{
      this.createQuizModalErrorMobileTarget.textContent = "";
      if (document.getElementById("title").value!==""){
        this.createQuizModalMobileTarget.classList.add("hidden");
      }else{
        e.preventDefault();
        e.stopImmediatePropagation();
        this.createQuizModalErrorMobileTarget.textContent = "Please enter a name of the quiz";
      }
    }
  }

  select_all_mobile(){
    const checkboxesArray = document.getElementsByName("saq_response_checkboxes");
    const selectAllCheckbox = document.getElementById('select_all_mobile');
    checkboxesArray.forEach((checkbox) => {
      checkbox.checked = selectAllCheckbox.checked;
    });
    this.updateLink();
  }

  openResetQuestionModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    this.resetQuestionModalTarget.classList.remove("hidden");
  }

  closeResetQuestionModal(e){
    this.resetQuestionModalTarget.classList.add("hidden");
  }

  openResetQuestionMobileModal(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    this.resetQuestionMobileModalTarget.classList.remove("hidden");
  }

  closeResetQuestionMobileModal(e){
    this.resetQuestionMobileModalTarget.classList.add("hidden");
  }

  toggleQuestionDetails(e){
    e.preventDefault();
    let saqId = e.target.dataset.saqId;
    const questionDetails = document.getElementById('saq-response-question-details-' + saqId);
    if(questionDetails){
      questionDetails.style.display = questionDetails.style.display === "none" ? "block" : "none";
    }
    const questionDetailsArrow = document.getElementById('saq-question-details-arrow-' + saqId);
    if(questionDetailsArrow){
      questionDetailsArrow.classList.toggle("rotated");
    }
    const questionDetailsArrowMobile = document.getElementById('saq-question-details-arrow-mobile-' + saqId);
    if(questionDetailsArrowMobile){
      questionDetailsArrowMobile.classList.toggle("rotated");
    }
  }

  hideTeachingPointModal(e){
    const saqResponseId = e.target.dataset.saqResponseId;
    const modal = this.teachingPointModalTargets.find(target => target.id === `tp_modal_${saqResponseId}`);
    if (modal) {
        modal.classList.add("hidden");
    }
  }

  openTeachingPointModal(e){
    window.renderAllChildReactOnRailsComponents?.(this.element);
    const saqResponseId = e.target.dataset.saqResponseId;
    const modal = this.teachingPointModalTargets.find(target => target.id === `tp_modal_${saqResponseId}`);
    if (modal) {
        modal.classList.remove("hidden");
    }
  }

  openTodoModal(e) {
    fetch(e.target.dataset.url)
      .then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .then(document.getElementById('todo_form_container').classList.remove("hidden"));
  }

  checkEnterFilterKey(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      const checkboxId = event.target.getAttribute('for'); // Get the id of the checkbox associated with the label
      const checkbox = document.getElementById(checkboxId); // Get the checkbox element
      checkbox.click();
    }
  }

  checkEnterKeySelectAll(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      event.target.click(); 
    }
  }

  checkEnterKeyToggleQuestionDetails(event) {  
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      this.toggleQuestionDetails(event); // Call the toggleQuestionDetails method
    }
  }

  checkEnterKeyCheckbox(event) {
    if (event.keyCode === 13) { // 13 is the key code for Enter
      event.preventDefault(); // Prevent the default action (form submission)
      event.target.click(); // Click the checkbox
    }
  }

}
