import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    registrationValues: Array,
    registrationColors: Array
  }

  connect() {
    this.renderPieChart();
    console.log(this.registrationValuesValue); // Should log the data passed to `data-pie-chart-values-value`
    console.log(this.registrationColorsValue);
    if (!this.registrationValuesValue.length) {
      this.registrationValuesValue = [1]; // Example fallback value
    }
    if (!this.registrationColorsValue.length) {
      this.registrationColorsValue = ['#ffffff']; // Example fallback color
    }
  }

  renderPieChart() {
    const values = this.registrationValuesValue;
    const colors = this.registrationColorsValue;
    let total = values.reduce((acc, val) => acc + val, 0);
    let gradient = 'conic-gradient(';
    let accumulatedPercentage = 0;

    values.forEach((value, index) => {
      let percentage = value / total * 100;
      let startPercentage = accumulatedPercentage;
      let endPercentage = accumulatedPercentage + percentage;
      gradient += `${colors[index]} ${startPercentage}% ${endPercentage}%, `;
      accumulatedPercentage = endPercentage;
    });

    gradient = gradient.slice(0, -2) + ')'; // Remove the last comma and space
    console.log(gradient);
    this.element.style.background = gradient;
  }
}