import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['slide'];

  get maxIndex() {
    if (this.hasSlideTarget) {
      return this.slideTargets.length - 1;
    }
    return 0;
  }

  scrollToNext() {
    if (this.maxIndex === 0) {
      return;
    }
    for (const slide of this.slideTargets) {
      if (slide.dataset.position === 'current') {
        slide.dataset.position = '';
      }
      if (slide.dataset.position === 'next') {
        slide.dataset.position = 'current';
        slide.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
        const current = parseInt(slide.dataset.index);
        if (current === this.maxIndex) {
          this.slideTargets.find((st) => {
            return parseInt(st.dataset.index) === 0;
          }).dataset.position = 'next';
        } else {
          this.slideTargets.find((st) => {
            return parseInt(st.dataset.index) === current + 1;
          }).dataset.position = 'next';
        }
        break;
      }
    }
  }
}
